import { useMemo, useState } from "react";
import { formatFilterData } from "helpers/projects/projects";

import { Button, FormGroup } from "components";

import { GroupHeader, SidebarSelect, TopicList } from "pages/Legislation/Admin/EditContent/TabPages";
import { Filter } from "hooks/interfaces/legislation.interface";
import {
  GENERAL_FILTER_TABS, JOB_ROLE_KEY,
  JURISDICTION_KEY, PRODUCT_SERVICE_KEY,
  SCOPE_KEY, SELECT_ALL_FILTER_KEY, STATUS_KEY, TOPIC_KEY, TYPE_KEY,
} from "configs/legislation/legislation";
import { EMPTY_ATTRIBUTES } from "configs/project/project";
import { SelectedAttributes } from "hooks/interfaces/project.interface";

interface ConfigAsideProps {
  filters: Filter[];
  showClearAllButton: boolean;
  onPillChange: (e: any) => void;
  onCheckboxChange: (e: any, checked: boolean) => void
  setClearAll: (value: boolean) => void;
  allSelectedAttributes: SelectedAttributes;
}

const ConfigAside = ({
  filters,
  showClearAllButton = false,
  setClearAll = () => {},
  onPillChange = () => {},
  onCheckboxChange,
  allSelectedAttributes = EMPTY_ATTRIBUTES,
}: ConfigAsideProps) => {

  const [productService, setProductService] = useState(filters.find((f) => f.label === PRODUCT_SERVICE_KEY)?.data || []);
  const [geographicalScope, setGeographicalScope] = useState(filters.find((f) => f.label === SCOPE_KEY)?.data || []);
  const [sustainabilityTopic, setSustainabilityTopic] = useState(filters.find((f) => f.label === TOPIC_KEY)?.data || []);
  const [legislationTypes, setLegislationTypes] = useState(filters.find((f) => f.label === TYPE_KEY)?.data || []);
  const [jurisdiction, setJurisdiction] = useState(filters.find((f) => f.label === JURISDICTION_KEY)?.data.filter((f) => f.is_approved)?.map((i) => i.name) || []);
  const [status, setStatus] = useState(filters.find((f) => f.label === STATUS_KEY)?.data || []);
  const [jobRoles, setJobRoles] = useState(filters.find((f) => f.label === JOB_ROLE_KEY)?.data || []);


  /**
   * Get the topic roles and legislation topics.
   */
  useMemo(() => {
    setStatus(formatFilterData(filters, STATUS_KEY, []));
    setLegislationTypes(formatFilterData(filters, TYPE_KEY, allSelectedAttributes.type.data));
    setSustainabilityTopic(formatFilterData(filters, TOPIC_KEY, allSelectedAttributes.topic.data));
    setJobRoles(formatFilterData(filters, JOB_ROLE_KEY, allSelectedAttributes.job_role_list.data));
    setProductService(formatFilterData(filters, PRODUCT_SERVICE_KEY, allSelectedAttributes.product_service.data));
    setGeographicalScope(formatFilterData(filters, SCOPE_KEY, allSelectedAttributes.geographical_scope.data));
    setJurisdiction(filters.find((f) => f.label === JURISDICTION_KEY)?.data.filter((f) => f.is_approved).map((i) => i.name) || []);
  }, [allSelectedAttributes, filters]);


  /**
   * Clear all selected attributes
   */
  const onClearAllAttributesHandler = () => {
    setClearAll(false);
    setJurisdiction([]);
    setStatus(formatFilterData(filters, STATUS_KEY, []));
    setJobRoles(formatFilterData(filters, JOB_ROLE_KEY, []));
    setLegislationTypes(formatFilterData(filters, TYPE_KEY, []));
    setGeographicalScope(formatFilterData(filters, SCOPE_KEY, []));
    setSustainabilityTopic(formatFilterData(filters, TOPIC_KEY, []));
    setProductService(formatFilterData(filters, PRODUCT_SERVICE_KEY, []));
    // setAttributesCleared(true);
    // setAllSelectedAttributes(EMPTY_ATTRIBUTES);
  };


  return (
    <article data-selected-attributes>
      <header>
        <h6>Selected client attributes</h6>
        {showClearAllButton ? (
          <Button
            variation="transparent"
            type="button"
            size="small"
            title="Clear all selected filters for this Client"
            onClick={() => setClearAll(true)}
          >
            Clear all selected attributes
          </Button>
        ): null }
      </header>

      <FormGroup data-edit-group="sustainability_topic">
        <GroupHeader title="sustainability topic" content="Choose the sustainability topic(s) that the project focuses on:"/>
        <TopicList data={sustainabilityTopic.filter((i: any) => i.name !== 'All Topic')} name="topic" onChange={onPillChange}/>
      </FormGroup>

      <FormGroup data-edit-group="issuing_jurisdiction">
        <GroupHeader title="'issuing' jurisdiction" content="Select the country or jurisdiction that issued the legislation"/>
        <SidebarSelect
          name="issuing_jurisdiction_country"
          sidebarItems={GENERAL_FILTER_TABS}
          selectedNav=''
          selectedJurisdictions={jurisdiction}
          options={filters.find((f) => f.label === JURISDICTION_KEY)?.data.filter((f) => !f.identifier.includes(SELECT_ALL_FILTER_KEY)).map((d) => d.name) || []}
          onCheckboxChange={onCheckboxChange}
          data-jurisdiction
        />
      </FormGroup>

      <FormGroup data-group="job_role_list">
        <GroupHeader title="job role list" content="Select the relevant job role for this legislation."/>
        <TopicList data={jobRoles} name="job_role_list" onChange={onPillChange}/>
      </FormGroup>

      <FormGroup data-edit-group="legislation_type">
        <GroupHeader title="legislation type" content="Select the current type of the legislation."/>
        <TopicList data={legislationTypes} name="type" onChange={onPillChange}/>
      </FormGroup>

      <FormGroup data-edit-group="relevant_product_group_service">
        <GroupHeader title="relevant product (group) or services" content="If applicable, specify whether the legislation applies to specific products and/ or services."/>
        <TopicList data={productService} name="product_service" onChange={onPillChange}/>
      </FormGroup>

      <FormGroup data-edit-group="geographical_scope">
        <GroupHeader title="geographical scope" content="If applicable, specify whether the legislation applies to specific products and/ or services."/>
        <TopicList data={geographicalScope} name="geographical_scope" onChange={onPillChange} />
      </FormGroup>
  </article>
  );

};

export default ConfigAside;
