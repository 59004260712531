import { useEffect, useRef, useState } from 'react';
import { DatePickerProps } from './DatePicker.interface';

import styles from './DatePicker.module.scss';
import { triggerEvent } from '@grrr/utils';
import { IconComponent } from 'components/Icon/Icon';

const TODAY = new Date();
const TODAY_ISO = TODAY.toISOString().split("T")[0];

const DatePicker: React.FC<DatePickerProps> = ({
  name,
  required,
  defaultValue,
  onChange,
  label,
  ...props
}: DatePickerProps) => {

  const dateInputRef = useRef<HTMLInputElement | null>(null);

  const [date, setDate] = useState<string>(defaultValue || TODAY_ISO);

  /**
   * Reset start date if stored start date is in the past.
   */
  useEffect(() => {
    if (new Date(defaultValue) < TODAY) {
      if (!dateInputRef.current) return;

      const input = dateInputRef.current;
      input.value = TODAY_ISO;
      triggerEvent(dateInputRef.current, 'input');
    }
  }, [defaultValue]);


  /**
   * Handle input clicks.
   */
  const inputClickHandler = async(e: any) => {
    const input = [...e.currentTarget.children]
      .find(node => node.tagName.toLowerCase() === 'input');

    if (input.disabled) {
      return;
    }

    try {
      // Supported from Chromium 99+
      await input.showPicker();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.root} {...props} onClick={inputClickHandler}>
      <label htmlFor={name}><span>{label}</span></label>
      <input
        type="date"
        name={name}
        required={required}
        defaultValue={defaultValue || TODAY_ISO}
        min={TODAY_ISO}
        aria-label={`Date for ${name}`}
        onChange={(e) => onChange(e.target.value)}
        ref={dateInputRef}
      />
      <IconComponent name="CalendarIcon" />
    </div>
  );

};

export default DatePicker;
