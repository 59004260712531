import { LEGISLATIONS, FILTERS, NAVIGATOR_LEGISLATIONS } from 'data/filters';

export const ACTIVE_LEGISLATION_FILTERS_ID = 'sln-active-legislation-filters';
export const NAVIGATOR_FILTER_PAYLOAD_ID = 'sln-navigator-filter-payload';
export const NAVIGATOR_FILTER_ID = 'sln-navigator-filter-data';
export const GUIDED_TOUR_ID = 'sln-guided-tour';

export const JOB_ROLE_KEY = 'job_role_list';
export const TYPE_KEY = 'type';
export const TOPIC_KEY = 'topic';
export const STATUS_KEY = 'status';
export const JURISDICTION_KEY = 'issuing_jurisdiction';
export const SCOPE_KEY = 'geographical_scope';
export const PRODUCT_SERVICE_KEY = 'product_service'
export const SELECT_ALL_FILTER_KEY = 'select-all-';
export const MAX_FILTER_NUMBER = 7;

// Tab columns
export const LEGISLATION_COLUMNS = ['name', 'applicable from', 'type'] as string[];

// Editing tabs
export const EDIT_TABS = [
  'General details',
  "Requirements type (optional)",
  "Role specific",
];

// Sorting
export const NAV_LEGISLATION_SORT_KEY = 'nav-legislations';
export const LEGISLATION_DETAILS_EDIT_MODAL_ID = 'legislation-details-edit-modal-id';

// REQUIREMENT TYPES/CATEGORIES
export const REQUIREMENT_TYPES = [
  {
    prefix: 'registration_requirements_',
    key: 'registration_requirements'
  },
  {
    prefix: 'reporting_requirements_',
    key: 'reporting_requirements',
  },
  { prefix: 'regulatory_requirements_',
    key: 'regulatory_requirements',
  }
];

// Registration requirements
export const REGISTRATION_REQUIREMENTS_FIELDS = [
  {
    groupName: 'description',
    name: 'registration_requirements_description',
    label: 'general description',
    subLabel: 'A general high-level description on the registration requirements.',
    defaultValue: '',
    maxLength: 1000,
    rows: 7,
    showCheckbox: false,
  },
  {
    groupName: 'responsible_authority',
    name: 'registration_requirements_responsible_authority',
    label: 'responsible authority',
    subLabel: 'Specify which authority is responsible for the enforcement of this legislation.',
    defaultValue: '',
    maxLength: 50,
    rows: 2,
    showCheckbox: false,
  },
  {
    groupName: 'trigger',
    name: 'registration_requirements_trigger',
    label: 'activities that trigger requirements',
    subLabel: 'A description of the activities that will trigger the obligation to comply with the registration requirements.',
    defaultValue: '',
    maxLength: 100,
    rows: 3,
    showCheckbox: false,
  },
  {
    groupName: 'responsible_party',
    name: 'registration_requirements_responsible_party',
    label: 'responsible party',
    subLabel: 'Defined within the legislation as the economic operator that must comply with the registration requirements.',
    defaultValue: '',
    maxLength: 100,
    rows: 5,
    showCheckbox: false,
  },
  {
    groupName: 'data_elements',
    name: 'registration_requirements_data_elements',
    label: 'data elements required',
    subLabel: 'Specify information necessary to submit the reporting application.',
    defaultValue: '',
    maxLength: 100,
    rows: 5,
    showCheckbox: false,
  },
  {
    groupName: 'payment_obligations',
    name: 'registration_requirements_payment_obligations',
    label: 'payment obligations',
    subLabel: 'Specific information necessary to submit the registration application.',
    defaultValue: '',
    maxLength: 100,
    rows: 5,
    showCheckbox: true,
  },
  {
    groupName: 'deadline',
    name: 'registration_requirements_deadline',
    label: 'deadline',
    subLabel: 'The due date by which the responsible party must comply with the registration requirements.',
    defaultValue: '',
    maxLength: 50,
    rows: 3,
    showCheckbox: false,
  },
  {
    groupName: 'threshold',
    name: 'registration_requirements_threshold',
    label: 'threshold',
    subLabel: 'If applicable, the minimum criteria that must be met before the registration requirements apply , as well as phase-in provisions.',
    defaultValue: '',
    maxLength: 200,
    rows: 5,
    showCheckbox: true,
  },
  {
    groupName: 'sanctions',
    name: 'registration_requirements_sanctions',
    label: 'sanctions',
    subLabel: 'If applicable, the penalties or consequences imposed as a result of non-compliance.',
    defaultValue: '',
    maxLength: 200,
    rows: 10,
    showCheckbox: true,
  },
  {
    groupName: 'exemptions',
    name: 'registration_requirements_exemptions',
    label: 'exemptions',
    subLabel: 'If applicable, the specific conditions under which the registration requirements do not apply.',
    defaultValue: '',
    maxLength: 200,
    rows: 5,
    showCheckbox: true,
  },
];

// Reporting requirements
export const REPORTING_REQUIREMENTS_FIELDS = [
  {
    groupName: 'description',
    name: 'reporting_requirements_description',
    label: 'general description',
    subLabel: 'A general high-level description on the registration requirements.',
    defaultValue: '',
    maxLength: 100,
    rows: 7,
    showCheckbox: false,
  },
  {
    groupName: 'responsible_authority',
    name: 'reporting_requirements_responsible_authority',
    label: 'responsible authority',
    subLabel: 'Specify which authority is responsible for the enforcement of this legislation.',
    defaultValue: '',
    maxLength: 50,
    rows: 2,
    showCheckbox: false,
  },
  {
    groupName: 'trigger',
    name: 'reporting_requirements_trigger',
    label: 'activities that trigger requirements',
    subLabel: 'A description of the activities that will trigger the obligation to comply with the registration requirements.',
    defaultValue: '',
    maxLength: 100,
    rows: 3,
    showCheckbox: false,
  },
  {
    groupName: 'responsible_party',
    name: 'reporting_requirements_responsible_party',
    label: 'responsible party',
    subLabel: 'Defined within the legislation as the economic operator that must comply with the registration requirements.',
    defaultValue: '',
    maxLength: 100,
    rows: 5,
    showCheckbox: false,
  },
  {
    groupName: 'data_elements',
    name: 'reporting_requirements_data_elements',
    label: 'data elements required',
    subLabel: 'Specify information necessary to submit the reporting application.',
    defaultValue: '',
    maxLength: 100,
    rows: 5,
    showCheckbox: false,
  },
  {
    groupName: 'language',
    name: 'reporting_requirements_language',
    label: 'language of reporting',
    subLabel: 'If there is any language of reporting, please specify that here.',
    defaultValue: '',
    maxLength: 100,
    rows: 5,
    showCheckbox: true,
  },
  {
    groupName: 'frequency',
    name: 'reporting_requirements_frequency',
    label: 'frequency of reporting',
    subLabel: 'If there is any language of reporting, please specify that here.',
    defaultValue: '',
    maxLength: 100,
    rows: 5,
    showCheckbox: true,
  },
  {
    groupName: 'deadline',
    name: 'reporting_requirements_deadline',
    label: 'deadline',
    subLabel: 'The due date by which the responsible party must comply with the registration requirements.',
    defaultValue: '',
    maxLength: 50,
    rows: 3,
    showCheckbox: false,
  },
  {
    groupName: 'way_of_submission',
    name: 'reporting_requirements_way_of_submission',
    label: 'way of submitting',
    subLabel: 'Specific information necessary to submit the registration application.',
    defaultValue: '',
    maxLength: 100,
    rows: 5,
    showCheckbox: true,
  },
  {
    groupName: 'payment_obligations',
    name: 'reporting_requirements_payment_obligations',
    label: 'payment obligations and rates',
    subLabel: 'Specific information necessary to submit the registration application.',
    defaultValue: '',
    maxLength: 100,
    rows: 5,
    showCheckbox: true,
  },
  {
    groupName: 'retainment_of_records',
    name: 'reporting_requirements_retainment_of_records',
    label: 'retainment of records',
    subLabel: 'Specific information regarding the retainment of records.',
    defaultValue: '',
    maxLength: 100,
    rows: 5,
    showCheckbox: true,
  },
  {
    groupName: 'refund_possibilities',
    name: 'reporting_requirements_refund_possibilities',
    label: 'refund possibilities',
    subLabel: 'Specific information regarding refund possibilities.',
    defaultValue: '',
    maxLength: 100,
    rows: 5,
    showCheckbox: true,
  },
  {
    groupName: 'threshold',
    name: 'reporting_requirements_threshold',
    label: 'threshold',
    subLabel: 'If applicable, the minimum criteria that must be met before the registration requirements apply , as well as phase-in provisions.',
    defaultValue: '',
    maxLength: 200,
    rows: 5,
    showCheckbox: true,
  },
  {
    groupName: 'sanctions',
    name: 'reporting_requirements_sanctions',
    label: 'sanctions',
    subLabel: 'If applicable, the penalties or consequences imposed as a result of non-compliance.',
    defaultValue: '',
    maxLength: 200,
    rows: 10,
    showCheckbox: true,
  },
  {
    groupName: 'exemptions',
    name: 'reporting_requirements_exemptions',
    label: 'exemptions',
    subLabel: 'If applicable, the specific conditions under which the registration requirements do not apply.',
    defaultValue: '',
    maxLength: 200,
    rows: 5,
    showCheckbox: true,
  },
];

// Regulatory requirements
export const REGULATORY_REQUIREMENTS_FIELDS = [
  {
    groupName: 'description',
    name: 'regulatory_requirements_description',
    label: 'general description',
    subLabel: 'A general high-level description on the registration requirements.',
    defaultValue: '',
    maxLength: 100,
    rows: 7,
    showCheckbox: false,
  },
  {
    groupName: 'responsible_authority',
    name: 'regulatory_requirements_responsible_authority',
    label: 'responsible authority',
    subLabel: 'Specify which authority is responsible for the enforcement of this legislation.',
    defaultValue: '',
    maxLength: 50,
    rows: 2,
    showCheckbox: false,
  },
  {
    groupName: 'trigger',
    name: 'regulatory_requirements_trigger',
    label: 'activities that trigger requirements',
    subLabel: 'A description of the activities that will trigger the obligation to comply with the registration requirements.',
    defaultValue: '',
    maxLength: 100,
    rows: 3,
    showCheckbox: false,
  },
  {
    groupName: 'responsible_party',
    name: 'regulatory_requirements_responsible_party',
    label: 'responsible party',
    subLabel: 'Specify which party is responsible for the enforcement of this legislation.',
    defaultValue: '',
    maxLength: 50,
    rows: 2,
    showCheckbox: false,
  },
  {
    groupName: 'key_actions',
    name: 'regulatory_requirements_key_actions',
    label: 'key actions',
    subLabel: 'Specify any key actions that must be taken to comply with the regulatory requirement.',
    defaultValue: '',
    maxLength: 50,
    rows: 2,
    showCheckbox: false,
  },
  {
    groupName: 'deadline',
    name: 'regulatory_requirements_deadline',
    label: 'deadline',
    subLabel: 'The due date by which the responsible party must comply with the registration requirements.',
    defaultValue: '',
    maxLength: 50,
    rows: 3,
    showCheckbox: false,
  },
  {
    groupName: 'threshold',
    name: 'regulatory_requirements_threshold',
    label: 'threshold',
    subLabel: 'If applicable, the minimum criteria that must be met before the registration requirements apply , as well as phase-in provisions.',
    defaultValue: '',
    maxLength: 200,
    rows: 5,
    showCheckbox: true,
  },
  {
    groupName: 'sanctions',
    name: 'regulatory_requirements_sanctions',
    label: 'sanctions',
    subLabel: 'If applicable, the penalties or consequences imposed as a result of non-compliance.',
    defaultValue: '',
    maxLength: 200,
    rows: 10,
    showCheckbox: true,
  },
  {
    groupName: 'exemptions',
    name: 'regulatory_requirements_exemptions',
    label: 'exemptions',
    subLabel: 'If applicable, the specific conditions under which the registration requirements do not apply.',
    defaultValue: '',
    maxLength: 200,
    rows: 5,
    showCheckbox: true,
  },
]

// LEGISLATION STATE
export const PUBLISHED_STATE = 'PUBLISHED';
export const CREATED_STATE = 'CREATED';
export const REVIEW_STATE = 'IN_REVIEW';
export const STORAGE_LEGISLATION_REFETCH_KEY = 'storage_legislation_refetch_key';


const DEFAULT_FILTER = {
  name: 'Default',
  label: 'Default',
  data: [
    {
      id: 1,
      created_at: 'string',
      updated_at: 'string',
      identifier: 'string',
      name: 'string',
      is_approved: false,
    }
  ]
}

// All Countries
export const COUNTRIES = {
  europe: [
    'European Union',
    'Austria',
    'Belgium',
    'Bulgaria',
    'Croatia',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Hungary',
    'Ireland',
    'Italy',
    'Latvia',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'The Netherlands',
    'Poland',
    'Portugal',
    'Romania',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'United Kingdom',
    'Albania',
    'Andorra',
    'Armenia',
    'Azerbaijan',
    'Belarus',
    'Bosnia and Herzegovina',
    'Estonia',
    'Faroe Islands',
    'Georgia',
    'Gibraltar',
    'Guernsey',
    'Iceland',
    'Isle of Man',
    'Jersey',
    'Kosovo',
    'Liechtenstein',
    'Macedonia',
    'Moldova',
    'Monaco',
    'Montenegro',
    'Norway',
    'Russia',
    'San Marino',
    'Serbia',
    'Switzerland',
    'Ukraine',
    'Vatican City',
  ],
  asia: [
    'Afghanistan',
    'Armenia',
    'Azerbaijan',
    'Bahrain',
    'Bangladesh',
    'Bhutan',
    'Brunei',
    'Cambodia',
    'China',
    'Cyprus',
    'Georgia',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
  ],
  africa: [
    'Algeria',
    'Angola',
    'Benin',
    'Botswana',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cameroon',
    'Central African Republic',
    'Chad',
    'Comoros',
    'Congo',
    'Djibouti',
    'Egypt',
    'Equatorial Guinea',
    'Eritrea',
    'Eswatini',
    'Ethiopia',
    'Gabon',
    'Gambia',
    'Ghana',
    'Guinea',
    'Guinea-Bissau',
    'Ivory Coast',
    'Kenya',
    'Lesotho',
    'Liberia',
    'Libya',
    'Madagascar',
    'Malawi',
    'Mali',
    'Mauritania',
    'Mauritius',
    'Morocco',
    'Mozambique',
    'Namibia',
    'Niger',
    'Nigeria',
    'Rwanda',
    'Sao Tome and Principe',
    'Senegal',
    'Seychelles',
    'Sierra Leone',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Sudan',
    'Tanzania',
    'Togo',
    'Tunisia',
    'Uganda',
    'Zambia',
    'Zimbabwe',
  ],
  north_america: [
    'Antigua and Barbuda',
    'Bahamas',
    'Barbados',
    'Belize',
    'Canada',
    'Costa Rica',
    'Cuba',
    'Dominica',
    'Dominican Republic',
    'El Salvador',
    'Grenada',
    'Guatemala',
    'Haiti',
    'Honduras',
    'Jamaica',
    'Mexico',
    'Nicaragua',
    'Panama',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Trinidad and Tobago',
    'United States',
  ],
  south_america: [
    'Argentina',
    'Bolivia',
    'Brazil',
    'Chile',
    'Colombia',
    'Ecuador',
    'Guyana',
    'Paraguay',
    'Peru',
    'Suriname',
    'Uruguay',
    'Venezuela',
  ],
  australia: [
    'Australia',
    'Fiji',
    'Kiribati',
    'Marshall Islands',
    'Micronesia',
    'Nauru',
    'New Zealand',
    'Palau',
    'Papua New Guinea',
    'Samoa',
    'Solomon Islands',
    'Tonga',
    'Tuvalu',
    'Vanuatu',
  ],
};

export const COUNTRIES_WITH_BOOLEAN = {
  europe: [
    { name: 'Austria', is_checked: false },
    { name: 'Belgium', is_checked: false },
    { name: 'Bulgaria', is_checked: false },
    { name: 'Croatia', is_checked: false },
    { name: 'Cyprus', is_checked: false },
    { name: 'Czech Republic', is_checked: false },
    { name: 'Denmark', is_checked: false },
    { name: 'Estonia', is_checked: false },
    { name: 'Finland', is_checked: false },
    { name: 'France', is_checked: false },
    { name: 'Germany', is_checked: false },
    { name: 'Greece', is_checked: false },
    { name: 'Hungary', is_checked: false },
    { name: 'Ireland', is_checked: false },
    { name: 'Italy', is_checked: false },
    { name: 'Latvia', is_checked: false },
    { name: 'Lithuania', is_checked: false },
    { name: 'Luxembourg', is_checked: false },
    { name: 'Malta', is_checked: false },
    { name: 'Netherlands', is_checked: false },
    { name: 'Poland', is_checked: false },
    { name: 'Portugal', is_checked: false },
    { name: 'Romania', is_checked: false },
    { name: 'Slovakia', is_checked: false },
    { name: 'Slovenia', is_checked: false },
    { name: 'Spain', is_checked: false },
    { name: 'Sweden', is_checked: false },
    { name: 'United Kingdom', is_checked: false },
  ],
  asia: [
    { name: 'Afghanistan', is_checked: false },
    { name: 'Armenia', is_checked: false },
    { name: 'Azerbaijan', is_checked: false },
    { name: 'Bahrain', is_checked: false },
    { name: 'Bangladesh', is_checked: false },
    { name: 'Bhutan', is_checked: false },
    { name: 'Brunei', is_checked: false },
    { name: 'Cambodia', is_checked: false },
    { name: 'China', is_checked: false },
    { name: 'Cyprus', is_checked: false },
    { name: 'Georgia', is_checked: false },
    { name: 'India', is_checked: false },
    { name: 'Indonesia', is_checked: false },
    { name: 'Iran', is_checked: false },
    { name: 'Iraq', is_checked: false },
  ],
  africa: [
    { name: 'Algeria', is_checked: false },
    { name: 'Angola', is_checked: false },
    { name: 'Benin', is_checked: false },
    { name: 'Botswana', is_checked: false },
    { name: 'Burkina Faso', is_checked: false },
    { name: 'Burundi', is_checked: false },
    { name: 'Cabo Verde', is_checked: false },
    { name: 'Cameroon', is_checked: false },
    { name: 'Central African Republic', is_checked: false },
    { name: 'Chad', is_checked: false },
    { name: 'Comoros', is_checked: false },
    { name: 'Congo', is_checked: false },
    { name: 'Djibouti', is_checked: false },
    { name: 'Egypt', is_checked: false },
    { name: 'Equatorial Guinea', is_checked: false },
    { name: 'Eritrea', is_checked: false },
    { name: 'Eswatini', is_checked: false },
    { name: 'Ethiopia', is_checked: false },
    { name: 'Gabon', is_checked: false },
    { name: 'Gambia', is_checked: false },
    { name: 'Ghana', is_checked: false },
    { name: 'Guinea', is_checked: false },
    { name: 'Guinea-Bissau', is_checked: false },
    { name: 'Ivory Coast', is_checked: false },
    { name: 'Kenya', is_checked: false },
    { name: 'Lesotho', is_checked: false },
    { name: 'Liberia', is_checked: false },
    { name: 'Libya', is_checked: false },
    { name: 'Madagascar', is_checked: false },
    { name: 'Malawi', is_checked: false },
    { name: 'Mali', is_checked: false },
    { name: 'Mauritania', is_checked: false },
    { name: 'Mauritius', is_checked: false },
    { name: 'Morocco', is_checked: false },
    { name: 'Mozambique', is_checked: false },
    { name: 'Namibia', is_checked: false },
    { name: 'Niger', is_checked: false },
    { name: 'Nigeria', is_checked: false },
    { name: 'Rwanda', is_checked: false },
    { name: 'Sao Tome and Principe', is_checked: false },
    { name: 'Senegal', is_checked: false },
    { name: 'Seychelles', is_checked: false },
    { name: 'Sierra Leone', is_checked: false },
    { name: 'Somalia', is_checked: false },
    { name: 'South Africa', is_checked: false },
    { name: 'South Sudan', is_checked: false },
    { name: 'Sudan', is_checked: false },
    { name: 'Tanzania', is_checked: false },
    { name: 'Togo', is_checked: false },
    { name: 'Tunisia', is_checked: false },
    { name: 'Uganda', is_checked: false },
    { name: 'Zambia', is_checked: false },
    { name: 'Zimbabwe', is_checked: false },
  ],
  north_america: [
    { name: 'Antigua and Barbuda', is_checked: false, },
    { name: 'Bahamas', is_checked: false, },
    { name: 'Barbados', is_checked: false, },
    { name: 'Belize', is_checked: false, },
    { name: 'Canada', is_checked: false, },
    { name: 'Costa Rica', is_checked: false, },
    { name: 'Cuba', is_checked: false, },
    { name: 'Dominica', is_checked: false, },
    { name: 'Dominican Republic', is_checked: false, },
    { name: 'El Salvador', is_checked: false, },
    { name: 'Grenada', is_checked: false, },
    { name: 'Guatemala', is_checked: false, },
    { name: 'Haiti', is_checked: false, },
    { name: 'Honduras', is_checked: false, },
    { name: 'Jamaica', is_checked: false, },
    { name: 'Mexico', is_checked: false, },
    { name: 'Nicaragua', is_checked: false, },
    { name: 'Panama', is_checked: false, },
    { name: 'Saint Kitts and Nevis', is_checked: false, },
    { name: 'Saint Lucia', is_checked: false, },
    { name: 'Saint Vincent and the Grenadines', is_checked: false, },
    { name: 'Trinidad and Tobago', is_checked: false, },
    { name: 'United States', is_checked: false, },
  ],
  south_america: [
    { name: 'Argentina', is_checked: false },
    { name: 'Bolivia', is_checked: false },
    { name: 'Brazil', is_checked: false },
    { name: 'Chile', is_checked: false },
    { name: 'Colombia', is_checked: false },
    { name: 'Ecuador', is_checked: false },
    { name: 'Guyana', is_checked: false },
    { name: 'Paraguay', is_checked: false },
    { name: 'Peru', is_checked: false },
    { name: 'Suriname', is_checked: false },
    { name: 'Uruguay', is_checked: false },
    { name: 'Venezuela', is_checked: false },
  ],
  australia: [
    { name: 'Australia', is_checked: false, },
    { name: 'Fiji', is_checked: false, },
    { name: 'Kiribati', is_checked: false, },
    { name: 'Marshall Islands', is_checked: false, },
    { name: 'Micronesia', is_checked: false, },
    { name: 'Nauru', is_checked: false, },
    { name: 'New Zealand', is_checked: false, },
    { name: 'Palau', is_checked: false, },
    { name: 'Papua New Guinea', is_checked: false, },
    { name: 'Samoa', is_checked: false, },
    { name: 'Solomon Islands', is_checked: false, },
    { name: 'Tonga', is_checked: false, },
    { name: 'Tuvalu', is_checked: false, },
    { name: 'Vanuatu', is_checked: false, },
  ],
};

export const REQUIREMENT = {
  id: 4,
  identifier: '',
  created_at: '',
  updated_at: '',
  description: '',
  responsible_authority: '',
  trigger: '',
  responsible_party: '',
  data_elements: '',
  payment_obligations: '',
  deadline: '',
  threshold: '',
  sanctions: '',
  exemptions: '',
  key_actions: '',
}

export const REG_REQUIREMENT = REQUIREMENT;
export const REGULATORY_REQUIREMENTS = REQUIREMENT;

export const NAVIGATOR_STATE_DEFAULTS = {
  id: 0,
  legislations: LEGISLATIONS || [],
  navLegislations: [],
  filteredNavLegislations: [],
  navTabs: [{
    label: 'string',
    entries: [],
    count: 0,
  }],
  filters: FILTERS || [DEFAULT_FILTER],
  query: '',
  sortOrder: 'asc',
  isLoading: true,
  isFetching: false,
};

export const STATE_DEFAULTS = {
  id: 0,
  legislations: [],
  filteredLegislations: [],
  filters: FILTERS || [DEFAULT_FILTER],
  legislationTabs: [{
    label: 'string',
    entries: [],
    count: 0,
  }],
  objective: '',
  current: {
    id: 0,
    identifier: 'string',
    created_at: 'string',
    updated_at: 'string',
    name_local: 'string',
    name_generic: 'string',
    abbreviation: 'string',
    effective_date: 'string',
    effective_until: 'string',
    non_compliance_risk: 'string',
    attention_point_list: [],
    background: 'string',
    link: 'string',
    additional_links: '',
    is_in_effect: false,
    responsible_authority: 'string',
    responsible_party: 'string',
    summary: 'string',
    objective: '',
    scope: 'string',
    preparation_state: 'string',
    status: 'string',
    review_cadence_months: 2,
    created_by: 1,
    pwc_contact: [],
    pwc_territory: '',

    // Filters
    type: [DEFAULT_FILTER],
    topic: [DEFAULT_FILTER],
    issuing_jurisdiction: [DEFAULT_FILTER],
    geographical_scope: [DEFAULT_FILTER],
    product_service: [DEFAULT_FILTER],
    job_role_list: [DEFAULT_FILTER],



    // Requirements
    registration_requirements:[],
    reporting_requirements: [],
    regulatory_requirements: [],
  },

  activeFilters: FILTERS || [DEFAULT_FILTER],
  query: '',
  isLoading: true,
  isFetching: false,
  sortOrder: 'asc',

  // expecting to be added to the state
  non_compliance_consequence: [],
  additional_link: '',
  region: '',
  responsible_party_summary: '',
  scope_summary: '',
};

// Legislation Details
export const FILTER_TABS =  [
  { identifier: 'role_specific_details', label: 'Role specific details', description: 'Role specific details' },
  { identifier: 'legislation_details', label: 'Legislation details', description: 'Legislation details' },
  { identifier: 'expert_contacts', label: 'Expert contacts', description: 'Expert contacts' },
];

export const DESCRIPTION: { [key: string]: { description: string, label: string } } = {
  role_specific_details: {
    description: 'Discover role-specific descriptions outlining how this legislation impacts different functions within your organization to understand the responsibilities and compliance implications tailored to different roles.',
    label: 'Role specific details',
  },
  legislation_details: {
    description: 'Explore the general details of the legislation and gain a thorough understanding of the different regulatory requirements and updates.',
    label: 'Legislation details',
  },
  expert_contacts: {
    description: 'Meet and learn about the experts and team involved in monitoring updates, ensuring accuracy, and providing support related to this regulatory framework.',
    label: 'Team',
  }
}

export const DEFAULT_APPROVED_FILTER = {
  status: [],
  effective_year: [],

  type: [],
  topic: [],
  job_role_list: [],
  product_service: [],
  geographical_scope: [],
  issuing_jurisdiction: [],
};

// GENERAL TAB
export const GENERAL_TAB_TITLE = 'General Details.'
export const GENERAL_TAB_CONTENT = 'Fill in the below metadata attributes to provide additional context and categorization for the active legislation.'
export const GENERAL_FILTER_TABS =  [
  { identifier: 'asia', label: 'Asia', description: 'Asia', countries: COUNTRIES.asia },
  { identifier: 'africa', label: 'Africa', description: 'Africa', countries: COUNTRIES.africa },
  { identifier: 'north_america', label: 'North America', description: 'North America', countries: COUNTRIES.north_america },
  { identifier: 'south_america', label: 'South America', description: 'South America', countries: COUNTRIES.south_america },
  { identifier: 'europe', label: 'Europe', description: 'Europe', countries: COUNTRIES.europe },
  { identifier: 'australia', label: 'Australia', description: 'Australia', countries: COUNTRIES.australia },
];
export const GENERAL_PRODUCT_TABS =  [
  { identifier: 'tech_products', label: 'Tech Products', description: 'Tech Products', countries: COUNTRIES.asia },
  { identifier: 'food_beverage', label: 'Food Beverage', description: 'Food Beverage', countries: COUNTRIES.africa },
  { identifier: 'household_consumer_goods', label: 'Household & Consumer Goods', description: 'Household & Consumer Goods', countries: COUNTRIES.north_america },
  { identifier: 'automotive_transportation', label: 'Automotive & Transportation', description: 'Automotive & Transportation', countries: COUNTRIES.south_america },
  { identifier: 'apparel_textile', label: 'Apparel & Textiles', description: 'Apparel & Textiles', countries: COUNTRIES.europe },
  { identifier: 'energy_power_generation', label: 'Energy & Power Generation', description: 'Energy & Power Generation', countries: COUNTRIES.australia },
];
export const GENERAL_IS_IN_EFFECT = [
  { name: 'Yes', label: 'Yes', identifier: 'yes' },
  { name: 'No', label: 'No', identifier: 'no' },
];

export const GENERAL_FILTER_DATA = ['topic', 'type', 'issuing_jurisdiction', 'geographical_scope', 'product_service']


// REQUIREMENT TAB
export const REQUIREMENT_KEYS = ['registration_requirement', 'reporting_requirement', 'regulatory_requirement'];
export const REQUIREMENT_TAB_TITLE = 'Requirements type (optional)';
export const REQUIREMENT_TAB_CONTENT = 'Fill in the below metadata attributes to provide additional context and categorization for the uploaded regulation.';
export const REQUIREMENTS = [
  {
    name: 'Registration Requirements',
    identifier: 'registration_requirements',
    data_type: 'registration_requirements',
    fields: REGISTRATION_REQUIREMENTS_FIELDS,
  },
  {
    name: 'Regulatory Requirements',
    identifier: 'regulatory_requirements',
    data_type: 'regulatory_requirements',
    fields: REGULATORY_REQUIREMENTS_FIELDS,
  },
  {
    name: 'Reporting Requirements',
    identifier: 'reporting_requirements',
    data_type: 'reporting_requirements',
    fields: REPORTING_REQUIREMENTS_FIELDS,
  },
];

export const REQUIREMENT_ERROR_FIELDS: { [key: string]: string } = {
  pwc_territory: 'PwC Territory',
  non_compliance_risk: 'Non Compliance Risk',
  background: 'Background',
  description: 'Description',
  responsible_authority: 'Responsible Authority',
  responsible_party: 'Responsible Party',
  trigger: 'Trigger',
  data_elements: 'Data Elements',
  payment_obligations: 'Payment Obligations',
  deadline: 'Deadline',
  threshold: 'Threshold',
  sanctions: 'Sanctions',
  exemptions: 'Exemptions',
  language: 'Language',
  frequency: 'Frequency',
  way_of_submission: 'Way of Submission',
  retainment_of_records: 'Retainment of Records',
  refund_possibilities: 'Refund Possibilities',
  key_actions: 'Key Actions',
}

// ROLE SPECIFIC TAB
export const ROLE_SPECIFIC_TAB_TITLE = 'Role specific'
export const ROLE_SPECIFIC_TAB_CONTENT = 'Fill in the below metadata attributes to provide additional context and categorization for the uploaded regulation.'
export const ROLE_SPECIFIC_JOB_ROLES = 'job_roles';
