import { Children, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { uuid } from '@grrr/utils';
import { getRelativeDateTime } from 'helpers/dateTime';

import {
  BackLink, Button, ButtonSet,
  Loader, Tabs, TopContent, IconComponent,
  EmptyLegislationList, TabList,
} from 'components';

import { useQueryApi } from 'hooks/index';
import { normalizeImageUrl, store } from 'helpers/utils';
import { composeLegislationNavigatorPayload } from 'helpers/legislations/legislation';
import { formatAndExtractAllAttributes, remapTeamMember } from 'helpers/projects/projects';
import { GET_ROLE_CONTENT } from 'configs/api-endpoints';
import { EMPTY_ATTRIBUTES, PROJECT_DETAILS_TABLIST } from 'configs/project/project';
import { NAVIGATOR_FILTER_PAYLOAD_ID,} from 'configs/legislation/legislation';

import { useUserContext, useProjectContext } from 'contexts';
import { withProjectHocs } from 'contexts/ProjectContext';

import { UserInterface } from 'hooks/interfaces';
import { Filter } from 'hooks/interfaces/legislation.interface';
import { Project, LegislationTabs } from 'pages/ProjectEdit/EditTabs/interfaces';
import { Client, ConfigLegislation, SelectedAttributes } from 'hooks/interfaces/project.interface';
import GroupMembers, { ProjectOwners } from 'pages/ProjectEdit/EditTabs/GroupMembers/Groupmembers';

import Card from '../ProjectEdit/EditTabs/ContentConfigEditTab/Card';
import styles from './ProjectDetails.module.scss';

const TAB_TITLE: { [key: number]: string } = {
  '1': 'Provide the general details about the client.',
  '2': 'PwC admins get access to the project environment. Admins can modify and publish content to the environment based on their permission rights.',
  '3': 'View the legislation pieces that match the attributes based on the client\'s needs and objectives.',
  '4': 'Preview the client environment from the client\'s perspective.'
}


const ProjectDetails = () => {

  const { projectId } = useParams();

  const tabRef = useRef<HTMLUListElement | null>(null);

  const { user } = useUserContext();
  const { state: projectState, isPending, canSendForApproval } = useProjectContext();

  const [selectedIndex, setSelectedIndex] = useState(1);
  const projectDetailsTablist = useMemo(() =>
    user.is_admin ? [ ...PROJECT_DETAILS_TABLIST ] : PROJECT_DETAILS_TABLIST,
    [user.is_admin]
  );


  /**
   * Handle tab click.
   */
  const tabClickHandler = (index: number) => {
    setSelectedIndex(Number(index));
  };

  const isPartOfProjectOwners = useMemo(() => {
    return projectState.project?.project_owner_list?.some((m) => m.identifier === user.profile.identifier);
  }, [projectState.project?.project_owner_list, user.profile.identifier]);


  return (
    <Fragment>
      <TopContent isDetails data-details-page>
        <BackLink>Back</BackLink>
        <div className={styles.root__topcontent}>
          <h3>{projectState.project?.name}</h3>
        </div>
        <p>View and edit the client environment.</p>

        {/* Tabs */}
        <Tabs
          tabSelected={selectedIndex}
          options={projectDetailsTablist.map((tab) => tab.label)}
          onTabClick={tabClickHandler}
          data-tabs
          align="left"
          type="tabs"
          showLastTabIcon={false}
          ref={tabRef}
        >
          {user.is_admin && isPartOfProjectOwners ? (
            <ButtonSet data-btn-set>
              <Button
                variation='primary'
                size="small"
                url={`/projects/${projectId}/edit`}
                title={`Publish: ${(projectState.project)?.name || ''}`}
                onClick={() => {}}
                data-edit-btn
              >
                Edit Project
              </Button>
            </ButtonSet>
          ) : null}
        </Tabs>
      </TopContent>

      <article className={styles.root__article} data-content>
        <p>{TAB_TITLE[selectedIndex]}</p>
        {projectState.project ? (
          <Fragment>
            <ClientDetails selectedIndex={selectedIndex} project={projectState.project}/>
            <AdminSetupDetails selectedIndex={selectedIndex} project={projectState.project}/>
            <ContentConfigurationDetails
              user={user}
              selectedIndex={selectedIndex}
              project={projectState.project}
              isPending={isPending}
              clientLegislations={projectState.clientLegislations}
              canRequestApproval={canSendForApproval}
            />
            <PreviewClientView selectedIndex={selectedIndex} project={projectState.project} filters={projectState?.filters}/>
          </Fragment>
        ) : (
          <Loader data-medium />
        )}
      </article>
    </Fragment>
  );

};

export default withProjectHocs(ProjectDetails);

interface EditComponentProps {
  selectedIndex: number,
  project: Project;
  direction?: string
  filters?: Filter[];
}
// Function to remove duplicates based on 'identifier'
const removeDuplicates = (array: any = []) => {
  const seen = new Set();
  return array.filter((item: any) => {
      const duplicate = seen.has(item?.identifier);
      seen.add(item?.identifier);
      return !duplicate;
  });
}

const ClientDetails = ({ selectedIndex, project }: EditComponentProps) => {
  /**
   * Compose avatar image.
   */
  const clientLogo = useMemo(
    () => normalizeImageUrl(project.logo).startsWith('data:image/jpeg;base64,') ? normalizeImageUrl(project.logo) : `data:image/jpeg;base64,${normalizeImageUrl(project.logo)}`,
    [project.logo],
  );

  return (
    <section className={styles.root__client_details} data-hidden={!(selectedIndex === 1)} data-create-content>
      {/* Logo of the client */}
      <article data-logo>
        <span>Company logo</span>
        <div data-client-logo>
          {project.logo ? (
            <img src={`${clientLogo}`} alt="Client Logo" />
          ) : <IconComponent name="ClientLogoIcon" />}
        </div>
        <p className={styles.root__logo_text}>You can upload a JPG or PNG file. The max file size is 10mb.</p>
        <p className={styles.root__logo_text}>*Please make sure the client has approved the use of their logo.</p>
      </article>

      {/* Client details */}
      <section data-edit-group="client_details">
        <div data-row>
          <span data-title>project name</span>
          <span data-subtitle>{project.name}</span>
        </div>

        <div data-row>
          <span data-title>project description</span>
          <span data-subtitle>{project.description}</span>
        </div>

        <div data-row>
          <span data-title>user email domain</span>
          <span data-subtitle>{project.domain}</span>
          <span data-domain>
            How will this domain be used? Based on the input provided regarding the clients domain, all the users that log in to the platform using that domain, will have access to this client environment. eg. if the domain is @pwc.com, all the pwc employees that log in using the PwC SSO will be redirected to the PwC legislation environment
          </span>
        </div>

        <div data-row>
          <span data-title>starting date of engagement</span>
          <span data-subtitle>{project.starting_date || 'Currently unknown'}</span>
        </div>
      </section>
    </section>
  );

};

const AdminSetupDetails = ({ selectedIndex, project }: EditComponentProps) => {

  const [clientMembers, setClientMembers] = useState<Client[] | []>(project.team_member_list || []);
  const [projectOwners, setProjectOwners] = useState<Client[] | []>(project.project_owner_list || []);
  const [groupedMembers, setGroupedMembers] = useState<any>([]);

  useEffect(() => {
    setClientMembers(project.team_member_list?.map((m) => ({
      ...remapTeamMember(m),
      role: m.groups[0] || 'preparer',
    })) || []);

    setProjectOwners(project.project_owner_list?.map((m) => ({
      ...remapTeamMember(m),
      role: 'project owner',
    })) || []);
  }, [project.project_owner_list, project.team_member_list]);

  /**
   * Group members based on their roles.
   */
  useEffect(() => {
    // lets make sure to always keep the project owner at the top
    const uniqueRoles = Array.from(new Set(['approver', 'preparer']));

    setGroupedMembers(uniqueRoles.map((role: string | undefined) => {
      return {
        role,
        subtitle: role?.toLowerCase() === 'approver' || role?.toLowerCase() === 'project owner'
          ? 'Master admins can do any content modifications within a client\'s environment and also publish the changes to the client\'s view.'
          : 'General admins can modify the content within a client\'s environment. ',
        members: clientMembers
          .filter((member: any) => member.role?.toLowerCase() === role?.toLowerCase())
          .filter((m) => !projectOwners.map((m) => m.identifier).includes(m.identifier)),
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[clientMembers, projectOwners, groupedMembers.length]);

  const onUpdateHandler = () => {};
  const onClientMemberRemoved = () => {};

  return (
    <section className={styles.root__admin_setup} data-hidden={!(selectedIndex === 2)} data-create-content>
      <article data-div-group>
        <GroupMembers
          groups={groupedMembers}
          onUpdateHandler={onUpdateHandler}
          onClientMemberRemoved={onClientMemberRemoved}
          type="details-page"
        />
        <ProjectOwners
          projectOwners={projectOwners as any}
          onUpdateHandler={onUpdateHandler}
          onClientMemberRemoved={onClientMemberRemoved}
          type="details-page"
        />
      </article>
    </section>
  );

};


const ContentConfigurationDetails = ({
  user,
  project,
  selectedIndex,
  isPending = false,
  clientLegislations = [],
  canRequestApproval = false,
}: EditComponentProps & {
  user: UserInterface,
  isPending?: boolean;
  canRequestApproval: boolean,
  clientLegislations?: ConfigLegislation[];
}) => {

  const [query, setQuery] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [legislationTabs, setLegislationTabs] = useState<LegislationTabs[]>([]);
  const [allSelectedAttributes, setAllSelectedAttributes] = useState<SelectedAttributes>(EMPTY_ATTRIBUTES);


  /**
   * Extract all unique attributes from the associated legislations
   */
  useEffect(() => {
    if (!clientLegislations.length || selectedIndex !== 3) return;
    const data = formatAndExtractAllAttributes(clientLegislations?.map((l: any) => l.legislation));
    setAllSelectedAttributes(data);
  }, [clientLegislations, selectedIndex]);


  const getRelativeDate = (legislation: any) => {
    const { value, label } = getRelativeDateTime(legislation.created_at || '', { includeTime: true });
    return `${value} ${label} ago`;
  };


  /**
   * Group legislations by tabs
   */
  useMemo(() => {
    const list: ConfigLegislation[] = clientLegislations || [];

    const tabs = [
      {
        label: 'In Review',
        entries: list.filter((l) => !l.is_approved),
        count: list.filter((l) => !l.is_approved).length,
      },
      {
        label: 'Approved',
        entries: list.filter((l) => l.is_approved),
        count: list.filter((l) => l.is_approved).length,
      },
    ];

    setLegislationTabs(tabs);
  }, [clientLegislations]);


  return (
    <section className={styles.root__content_configuration} data-hidden={!(selectedIndex === 3)} data-create-content>
      <aside>
        <h6>Selected attributes</h6>
        {Children.toArray(Object.keys(allSelectedAttributes).map((key: string) => (
          <ul>
            <span>{allSelectedAttributes[key].label}</span>
            {!allSelectedAttributes[key].data.length && isPending ? (
              <li> <Loader /></li>
            ) : null}
            {Children.toArray(allSelectedAttributes[key].data?.map((attribute: any) => (
              <li>
                <span>{attribute?.name}</span>
              </li>
            )))}
            {allSelectedAttributes[key].data.length ? (
              null
            ) : (
              <li data-empty><small>No attributes available for this group.</small></li>
            )}
          </ul>
        )))}
        {user.is_admin ? (
          <ButtonSet data-btn-set>
          <Button variation='secondary-trans' url={`/projects/${project.identifier}/edit`}>
            <IconComponent name="PencilOutlineIcon"/>
            Configure client legislation list
          </Button>
        </ButtonSet>
        ) : null}
      </aside>

      <article data-legislation-content>
        <header>
          <h6>
            {!canRequestApproval
              ? 'Configured legislation list'
              : 'Matching legislation to attributes'
            } &nbsp;| <span>{clientLegislations.length || 0} results</span>
          </h6>
        </header>

        <div data-legislation-list>
          <TabList
            user={user}
            query={query}
            entries={legislationTabs}
            onDataSort={() => {}}
            isEditing={false}
            selectedTab={selectedTab}
            onSearch={() => {}}
            setSelectedTab={setSelectedTab}
            onFilterOption={() => {}}
            variant="project-tabs"
            showSearch
          >
            {legislationTabs.map((tab, index) => (
                <Fragment key={index}>
                  {isPending ?  <Loader data-details /> : (
                    <ul data-tab-list>
                      {Children.toArray(tab.entries.length ? tab.entries.map((configLeg: any, _i: number) => (
                        <Card
                          user={user}
                          query={query}
                          configLeg={configLeg}
                          setIsEditing={() => {}}
                          setCurrentEditCard={() => {}}
                        />
                      )) : (
                        <EmptyLegislationList showContent={tab.entries.length === 0} query={query} />
                      ))}
                    </ul>
                  )}
                </Fragment>
              ))}
            </TabList>
        </div>
      </article>
    </section>
  );

};

const PreviewClientView = ({ selectedIndex, project, filters = [] }: EditComponentProps) => {

  const clientProjectAccordionId = useMemo(() => uuid(), []);


  const { user } = useUserContext();
  // const { state, stateDispatch: dispatch, stateActions } = useNavigatorContext();
  const isActiveFiltersEmpty = filters.map((f) => f.data.every((o) => !o.is_approved)).every((c) => c);


  const { post: getRoleContent } = useQueryApi(GET_ROLE_CONTENT);
  const { data: roleContentData, mutate: mutateRoleContent, isSuccess, isPending } = getRoleContent();

  // State
  const [LegislationRoleContent, setLegislationRoleContent] = useState<any[]>([])
  const [activeKeys, setActiveKeys] = useState<string[]>();

  /**
   * Handles accordion click event
   */
  const onClickAccordion = (activeKeys: string[]) => {
    setActiveKeys(activeKeys);
  };

  /**
   * Handles the filter change
   */
  const onFilterChangeHandler = (checked: boolean, filterOption: Filter, name:string) => {
    // dispatch(stateActions.setActiveFilters(checked, filterOption, name ?? ''));
  };

  /**
   * Filter the filters for only the categories that has any filter(s) already approved/checked
   */
  const activeFilters = useMemo(() => {
    return filters
      ?.filter((_f) => _f.data.some((d) => d.is_approved ? true : false))
      ?.map((f) => ({
        ...f,
        data: f.data.filter((_d) => _d.is_approved)
      }))
  }, [filters]);


  /**
   * Handles the navigation to the legislation page
   */
  const onNavigateLegislationHandler = () => {
    const payload = composeLegislationNavigatorPayload(
      filters,
      [] // Empty because the ADMIN is looking at this now
    );
    store(NAVIGATOR_FILTER_PAYLOAD_ID, payload, { permanent: false });

    if (!payload.job_role_list.length && !Object.keys(payload.selectors).length) {
      return;
    }
    mutateRoleContent(payload);
  };


  /**
   * Set the navigator legislation data
   */
  useEffect(() => {
    if (!roleContentData?.errors?.length && isSuccess) {
      // const { results } = roleContentData;
      // const  filtered = results.filter((nav: NavigatorLegislation) => isMatchedSearched(nav.legislation, query));
      // const filteredNavLegislations = sortNavigatorLegislationData(filtered, sortOrder);
      // dispatch(stateActions.initState({ navLegislations: results || [], filteredNavLegislations }));
      // setLegislationRoleContent(results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);


  /**
   * Handle close/remove clicks.
   */
  const removeHandler = (id: string) => {
    const filterOption = filters.find((f) => {
      return f.data.some((d) => d.identifier === id)
    });

    if (filterOption) {
      const name = filterOption.data.find((d) => d.identifier === id)?.name;
      // dispatch(stateActions.setActiveFilters(false, filterOption, name));
      return;
    }
    console.warn('could not find the filter option to be updated........')
  };

  /**
   * Handle search input updates and fetch new queries.
   */
  const onSearch = (e: any) => {
    const query = e.target.value;
    // dispatch(stateActions.setQuery(query || ''));
  };

  /**
   * Handle sort change.
   */
  const onSortChangeHandler = (e: any) => {
    const target = e.target.closest('button') as HTMLElement;
    const sortValue = target.dataset.sortValue || 'asc';
    // dispatch(stateActions.setSortOrder(sortValue));
    // dispatch(stateActions.sortNavLegislations(sortValue));
  };


  /**
   * Dropdown Filter handler
   */
  const onDataFilterHandler = (filterValue: string) => {
    // dispatch(stateActions.setFilterBy({ filterValue }));
  };

  return (
    <></>
  );

  // return (
  //   <section className={styles.root__preview_client_view} data-hidden={!(selectedIndex === 4)} data-create-content>
  //     <Aside user={user}>
  //       <header data-aside-header>
  //         <h4 className={styles.root__header__title}>Selector</h4>
  //         <span>Select your fields of interest</span>
  //       </header>

  //       <Filters
  //         onSideFilterChange={onFilterChangeHandler}
  //         filters={filters}
  //         // This is empty bcos the admin does not need it -- it just a preview of what the client will see
  //         userRoles={[]}
  //         data-nav-landing
  //         data-accordion
  //       >
  //         <ButtonSet data-aside-button>
  //           <Button
  //             type='button'
  //             variant="primary"
  //             onClick={onNavigateLegislationHandler}
  //             disabled={isActiveFiltersEmpty}
  //           >
  //             Navigate legislation
  //           </Button>
  //         </ButtonSet>
  //       </Filters>
  //     </Aside>

  //     {/* Main Content on the Left */}
  //     {!isPending && !isSuccess && !LegislationRoleContent.length ? (
  //       <article data-legislation-content data-empty-state>
  //         <IconComponent name="EmptyNavigator" data-empty-icon />
  //         <div className={styles.root__searchempty}>
  //           <IconComponent name="SearchEmptyState" />
  //         </div>
  //         <h6 className={styles.root__searchemptytext}>
  //           Select your preferred filters to navigate legislations relevant to you and your defined role(s)
  //         </h6>
  //       </article>
  //     ) : (
  //       <article data-legislation-content>
  //          <header>
  //           <span>Refine the filtering further, may give you more results: </span>
  //           <div>
  //             <Icon name="exclamation-triangle" />
  //             <span><strong>Disclaimer:</strong>&nbsp;Users advised to verify and cross-reference information.</span>
  //           </div>
  //         </header>
  //         <ul className={styles.root__filters} data-show-guide={user.show_filters_tour}>
  //           {activeFilters.length ? Children.toArray(activeFilters.map((f: Filter, index: number) => (
  //             Children.toArray(f?.data.map((o) => (
  //               <FilterItem
  //                 hidden={o.is_approved || false}
  //                 option={o}
  //                 onItemRemove={removeHandler}
  //               />
  //             ))
  //           )))) : null}
  //         </ul>
  //         {/* Search Filter  */}
  //         <SearchFilter
  //           onSearch={onSearch}
  //           onDataSort={onSortChangeHandler}
  //           onFilterOptionSelect={onDataFilterHandler}
  //           id={clientProjectAccordionId}
  //         />

  //         {state.navLegislations.length ? (
  //           <Accordion
  //             multiple={false}
  //             onClick={onClickAccordion}
  //             activeKeys={activeKeys}
  //             accordionId={clientProjectAccordionId}
  //             data-show-guide={false}
  //           >
  //             {state.navLegislations.length ? Children.toArray(state.navLegislations.map((navLegislation, index: number) => (
  //               <AccordionItem
  //                 contentTitle={navLegislation.legislation.name_local || 'No name available'}
  //                 description={`Type: ${navLegislation?.legislation?.scope}` || 'No scope available'}
  //                 itemKey={navLegislation.legislation.identifier}
  //                 query={state.query}
  //                 isLarge
  //               >
  //                 <LegislationListCard
  //                   key={navLegislation.legislation.identifier}
  //                   legislation={navLegislation as NavigatorLegislation}
  //                   user={user}
  //                   query={state.query}
  //                   seCurrentLegislation={
  //                     () => dispatch(stateActions.seCurrentLegislation(navLegislation.legislation.identifier))
  //                   }
  //                   isListCard={false}
  //                 />
  //               </AccordionItem>
  //             ))) : null}
  //           </Accordion>
  //         ) : null }
  //         {/* Empty message */}
  //         <EmptyLegislationList showContent={!state.navLegislations.length} query={state.query}></EmptyLegislationList>
  //       </article>
  //     )}
  //   </section>
  // );

};
