import { useRef, useMemo, useLayoutEffect, useEffect, useState, Children } from 'react';
import { uuid } from '@grrr/utils';
import { stripTrailingNumbers } from 'helpers/utils';

import {
  FormGroup,
  FormEditInput,
  Accordion,
  AccordionItem,
  IconComponent,
  Button,
  Checkbox,
} from 'components';
import { EditHeader, GroupHeader } from '..';
import { RequirementInterface, RequirementsProps } from '../interfaces';
import { REQUIREMENT_TAB_TITLE, REQUIREMENT_TAB_CONTENT, REQUIREMENTS, REQUIREMENT_KEYS } from 'configs/legislation/legislation';
import { Legislation } from 'hooks/interfaces';
import { Filter } from 'hooks/interfaces/legislation.interface';
import { createNewRequirement } from 'helpers/legislations/legislation';


interface RequirementGroup {
  key: string;
  data: any[];
  fields: any[];
  name: string;
  is_new_requirement: boolean;
}
export interface Requirement {
  id: number;
  identifier: string;
  created_at: string;
  updated_at: string;
  description: string;
  responsible_authority: string;
  trigger: string;
  responsible_party: string;
  data_elements: string;
  payment_obligations: string;
  key_actions: string;
  deadline: string;
  threshold: string;
  sanctions: string;
  exemptions: string;
}


const Requirements = ({
  filters = [] as Filter[],
  data = {} as Legislation,
  onNotApplicable,
  selectedIndex,
  setMissingFields,
  isEditing = false,
  ...props
}: RequirementsProps) => {

  const roleSpecificAccordionId = useMemo(() => uuid(), []);
  const groupRef = useRef<HTMLDivElement | null>(null);

  const [requirementList, setRequirementList] = useState<string[]>([]);
  const [requirementGroup, setRequirementGroup] = useState<RequirementGroup[]>([] as RequirementGroup[]);
  const [activeKeys, setActiveKeys] = useState<string[]>([requirementGroup[-1]?.data[-1]?.identifier || '']);

  /**
   * Reset the requirement list and group when the selected index is not 2
   */
  useEffect(() => {
    if (!isEditing) {
      setRequirementList([]);
      setRequirementGroup([]);
    }
  }, [isEditing]);


  /**
   * Check in the data if registration_requirement, reporting_requirement, or regulatory_requirement
   * is present and is not empty, then add it to the requirement list and set the active keys and
   * update the requirement group
   */
  useEffect(() => {
    if (requirementList.length) {
      setActiveKeys([requirementGroup[-1]?.data[-1]?.identifier || requirementGroup[0]?.key || '']);
      return;
    }

    const activeKeys: string[] = REQUIREMENT_KEYS.filter((key) => {
      if (data[`${key}s` as keyof Legislation]) {
        return (data[`${key}s` as keyof Legislation] as RequirementInterface[])?.length;
      }
      return false;
    }) as string[] || [];

    const dataRequirements = activeKeys.map((key) => ({
      key,
      data: (data[`${key}s` as keyof Legislation] as Requirement[])
        .map((r, i) => ({
          ...r, identifier: r.identifier ? r.identifier : i === 0 ? `${key}` : `${key}_${i + 1}`,
          is_new_requirement: !r.identifier,
        })),
      fields: REQUIREMENTS.find((req) => req.identifier.includes(key))?.fields,
      name: REQUIREMENTS.find((req) => req.identifier.includes(key))?.name,
      is_new_requirement: false,
    })).flat() as RequirementGroup[];

    if (activeKeys.length) {
      setRequirementList(activeKeys);
      setActiveKeys(activeKeys);
      setRequirementGroup(dataRequirements);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, requirementList.length]);


  /**
   * Set the active keys when the requirement group changes to the last added requirement
   */
  useEffect(() => {
    if (requirementGroup.length && !requirementList.length) {
      setActiveKeys([requirementGroup[-1]?.data[-1]?.identifier || requirementGroup[0]?.key || '']);
    }
  }, [requirementGroup, requirementList]);



  /**
   * Handles accordion click event
   * @param activeKeys
   */
  const onClickAccordion = (activeKeys: string[]) => {
    setActiveKeys(activeKeys);
  };


  /**
   * Handles removing an already added requirement
   * @param e
   */
  const onRequirementRemoveHandler = (e: any) => {
    const roleName = e.target.closest('li').dataset.requirementKey;

    if (!roleName) return;
    const requirement = requirementGroup.find((role: any) => role.identifier && (role.identifier === roleName));

    if (!requirement) return;

    const updatedRequirements = requirementGroup.filter((req) => req.name !== requirement.name);
    setRequirementGroup(updatedRequirements);
    setMissingFields([]);
  };


  /**
   * Add a requirement to the list of requirements
   * @param requirement
   */
  const addRequirement = (requirement: string) => {
    if (!requirementList.includes(requirement)) {
      setRequirementList([...requirementList, requirement]);
    }

    if (requirementList.includes(requirement)) {
      setRequirementList(requirementList.filter((r: string) => r !== requirement));
    }
  };

   /**
   * Add more handler
   * When clicked, it will add another requirement to the requirement group list and display the fields
   */
   const onAddMore = (e: any, role: any, index: number ) => {
    const newRequirement = createNewRequirement(role, index);

    if (!newRequirement) return;
    setRequirementGroup([...requirementGroup, newRequirement]);
    setActiveKeys([newRequirement.identifier]);

    if (groupRef.current) {
      groupRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  /**
   * Scroll to the top when new legislation requirement is added
   */
  // useLayoutEffect(() => {
  //   if (groupRef.current) {
  //     groupRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [activeKeys]);


  /**
   * Set the requirement group
   */
  useEffect(() => {

    const dataRequirements = requirementList.map((key) => ({
      key,
      data: (data[`${key}s` as keyof Legislation] as Requirement[])
        ?.map((r, i) => ({
          ...r, identifier: r.identifier ? r.identifier : i === 0 ? `${key}` : `${key}_${i + 1}`,
          // is_new_requirement: !r.identifier,
        })),
      fields: REQUIREMENTS.find((req) => req.identifier.includes(key))?.fields,
      name: REQUIREMENTS.find((req) => req.identifier.includes(key))?.name,
      is_new_requirement: false,
    })).flat() as RequirementGroup[];

    setRequirementGroup(dataRequirements);
  }, [data, requirementList]);


  return (
    <div data-content-wrapper {...props} ref={groupRef} data-hidden={!(selectedIndex === 2)}>
      <FormGroup>
        <EditHeader title={REQUIREMENT_TAB_TITLE} content={REQUIREMENT_TAB_CONTENT} />
      </FormGroup>

      <FormGroup data-group="type_of_requirements">
        <GroupHeader title="type of requirements" content="Select which type of requirements are included in the legislation."/>
        <Checkbox
          name="registration_requirement"
          inputId='registration_requirement_check'
          checked={requirementList.includes('registration_requirement')}
          onChange={() => addRequirement('registration_requirement') }
          data-requirements-check
        >
          <strong>Registration requirements:</strong> Refer to the process of officially enrolling or recording certain information with a relevant authority or organization. It typically involves providing specific details about an individual, entity, or activity to establish legal recognition or compliance
        </Checkbox>
        <Checkbox
          name="reporting_requirement"
          inputId='reporting_requirement_check'
          checked={requirementList.includes('reporting_requirement')}
          onChange={() => addRequirement('reporting_requirement')}
          data-requirements-check
        >
          <strong>Reporting requirements:</strong> Rules, or obligations on either submitting information to an external party such as a a relevant authority or organization, as well as rules or obligations on publishing information in for instance an annual statement
        </Checkbox>
        <Checkbox
          name="regulatory_requirement"
          inputId='regulatory_requirement_check'
          checked={requirementList.includes('regulatory_requirement')}
          onChange={() => addRequirement('regulatory_requirement')}
          data-requirements-check
        >
          <strong>Regulatory requirements:</strong> Regulatory requirements are rules, standards, or obligations established to govern activities or entities that must be complied with. (all requirements that are not registration or reporting requirements).
        </Checkbox>
      </FormGroup>

      <FormGroup data-original-group >
        <GroupHeader data-no-requirement={!requirementGroup.length} required={requirementGroup.length > 0} title="selected requirement" data-requirement-group>
          <p data-group-subtitle>
            Click on <IconComponent name="PlusFillIcon"/>
            to expand on each requirement and add more details about it
          </p>
        </GroupHeader>

        {requirementList.length ? (
          <Accordion
            multiple={false}
            activeKeys={activeKeys}
            accordionId={`role-specific-accordion-${roleSpecificAccordionId}`}
            onClick={onClickAccordion}
          >
            {Children.toArray(requirementGroup.map((requirement: any, requirementIndex: number) => {
              if (!requirement.data?.length) {
                return (
                  <AccordionItem
                    key={requirementIndex}
                    itemKey={requirement.identifier}
                    data-role-name={requirement.name}
                    onRemove={onRequirementRemoveHandler}
                    data-requirement-key={requirement.identifier}
                    contentTitle={stripTrailingNumbers(requirement.name)}
                    data-requirement-data-key={requirement?.data[requirementIndex]?.identifier}
                    isNew={requirement?.is_new_requirement || !requirement?.data?.length}
                    data-requirements
                    data-is-new={requirement.is_new_requirement}
                    data-is-editing
                    isLarge
                  >
                  <div data-subtitle data-requirements>
                    Refer to the process of officially enrolling or recording certain information with a relevant authority or organization. It typically involves providing specific details about an individual, entity, or activity to establish legal recognition or compliance.
                  </div>

                  {requirement?.fields?.map((field: any, index: number) => {
                    let defaultValue = requirement[field.groupName] || '';

                    if (Array.isArray(defaultValue)) {
                      defaultValue = defaultValue.join('\n');
                    }

                    return (
                      <FormGroup data-group="description" data-requirements key={`${field.groupName}-$${index}`}>
                        <FormEditInput
                          name={`${field.name}-${requirementIndex + 1}` || ''}
                          type='text'
                          label={field.label ?? ''}
                          subLabel={field.subLabel ?? ''}
                          defaultValue={defaultValue ?? ''}
                          maxLength={field.maxLength ?? 100}
                          rows={field.rows ?? 5}
                          showCheckbox={field.showCheckbox ?? false}
                          onNotApplicable={onNotApplicable}
                          isTextArea
                          required
                        />
                      </FormGroup>
                    );
                  })}
                  <input
                    type='hidden'
                    name={`${requirement.key}s_identifier-${requirementIndex + 1}`}
                    value={`${requirement?.data[requirementIndex]?.identifier}`}
                  />
                  <FormGroup data-group="description" data-requirements key={`${requirement.name}-${requirementIndex}${requirement.identifier}`}>
                    <Button
                      variation="secondary-trans"
                      onClick={(e: any) => onAddMore(e, requirement, requirementGroup.length)}
                      data-role-name={requirement.name}
                      data-role-index={requirementIndex}
                      data-add-more
                    >
                      <IconComponent name="PlusFillIcon" />
                      &nbsp;<span>Add another {stripTrailingNumbers(requirement.name)} </span>
                    </Button>
                  </FormGroup>
                  </AccordionItem>
                );
              }

              return Children.toArray(requirement.data.map((currentRequirement: any = {}, index: number) => (
                <AccordionItem
                  itemKey={currentRequirement.identifier}
                  contentTitle={stripTrailingNumbers(requirement.name)}
                  isNew={requirement.is_new_requirement}
                  onRemove={onRequirementRemoveHandler}
                  data-is-editing
                  data-requirements
                  data-is-new={currentRequirement.is_new_requirement}
                  data-requirement-key={currentRequirement.identifier}
                  data-role-name={requirement.name}
                  isLarge
                >
                  <div data-subtitle data-requirements>
                    Refer to the process of officially enrolling or recording certain information with a relevant authority or organization. It typically involves providing specific details about an individual, entity, or activity to establish legal recognition or compliance.
                  </div>

                  {requirement?.fields?.map((field: any, index: number) => {
                    let defaultValue = currentRequirement[field.groupName] || '';
                    if (Array.isArray(defaultValue)) {
                      defaultValue = defaultValue.join('\n');
                    }

                    return (
                      <FormGroup data-group="description" data-requirements key={`${field.groupName}-$${index}`}>
                        <FormEditInput
                          name={`${field.name}-${requirementIndex + 1}` || ''}
                          type='text'
                          label={field.label ?? ''}
                          subLabel={field.subLabel ?? ''}
                          defaultValue={defaultValue ?? ''}
                          maxLength={field.maxLength ?? 100}
                          rows={field.rows ?? 5}
                          showCheckbox={field.showCheckbox ?? false}
                          onNotApplicable={onNotApplicable}
                          isTextArea
                          required
                        />
                      </FormGroup>
                    );
                  })}
                  <input
                    type='hidden'
                    name={`${requirement.key}s_identifier-${requirementIndex + 1}`}
                    value={`${currentRequirement.identifier}`}
                  />
                  <FormGroup data-group="description" data-requirements key={`${requirement.name}-${requirementIndex}${requirement.identifier}`}>
                    <Button
                      variation="secondary-trans"
                      onClick={(e: any) => onAddMore(e, requirement, requirementGroup.length)}
                      data-role-name={requirement.name}
                      data-role-index={requirementIndex}
                      data-add-more
                    >
                      <IconComponent name="PlusFillIcon" />
                      &nbsp;<span>Add another {stripTrailingNumbers(requirement.name)} </span>
                    </Button>
                  </FormGroup>
                </AccordionItem>
              )));
            }))}
          </Accordion> ) : (
          <small data-is-empty>
            No requirements have been added yet. Please select a requirement type to add requirement specific details.
          </small>
        )}
      </FormGroup>
    </div>
  );

};

export default Requirements;
