import { Children, useMemo, useState } from 'react'
import { uuid } from '@grrr/utils';
import { isLeftKey, isRightKey, isDownKey } from 'helpers/utils';

import { SearchFilter } from '..';
import { TabListProps } from './TabList.interface';
import { LEGISLATION_COLUMNS } from 'configs/legislation/legislation';
import styles from './TabList.module.scss';


const TabList = ({
  selectedTab = 0,
  setSelectedTab,
  entries = [],
  user,
  distribute = false,
  showSearch = false,
  isEditing = false,
  onFilterOption,
  onTabClick,
  onSearch,
  onDataSort,
  children,
  variant = 'admin-tabs',
  ...props
}: TabListProps) => {

  const id = useMemo(() => uuid(), []);

  const [selectedIndex, setSelectedIndex] = useState(selectedTab);
  const isPublishedTab = entries[selectedTab]?.label.toLowerCase() === 'published';
  const headerColumns = useMemo<string[]>(
    () => variant === "project-tabs" ? ['name', 'review', ''] :
      isEditing ? ['name', ''] : user?.is_preparer || user?.is_approver
      ? [
        ...LEGISLATION_COLUMNS, isEditing ? '' : `${user?.is_approver && isPublishedTab
          ? 'approved at' : `${isPublishedTab ? 'approved at' : 'created at' }`
        }`
      ] : LEGISLATION_COLUMNS,
    [isEditing, user?.is_preparer, user?.is_approver, isPublishedTab, variant]
  );


  /**
   * Node index getter.
   */
  const getIndexFromNode = (node: any) => parseInt(node.getAttribute('data-index'), 10);


  /**
   * Handle tab click.
   */
  const tabClickHandler = (e: any) => {
    const index = getIndexFromNode(e.currentTarget);
    setSelectedIndex(index);
    setSelectedTab && setSelectedTab(index);
    e.preventDefault();

    if (typeof onTabClick === 'function') {
      onTabClick(Number.parseFloat(e.target.closest('a').dataset.index));
    }
  };


  /**
   * Handle tab key down.
   */
  const tabKeyDownHandler = (e: any) => {
    const target = e.currentTarget;

    // Handle `left` key event.
    if (isLeftKey(e) && selectedIndex > 0) {
      target.parentNode.previousElementSibling.firstElementChild.focus();
      setSelectedIndex(selectedIndex - 1);
      setSelectedTab && setSelectedTab(selectedIndex - 1);
      e.preventDefault();
    }

    // Handle `right` key event.
    if (isRightKey(e) && selectedIndex < (entries.length - 1)) {
      target.parentNode.nextElementSibling.firstElementChild.focus();
      setSelectedIndex(selectedIndex + 1);
      setSelectedTab && setSelectedTab(selectedIndex + 1);
      e.preventDefault();
    }

    // Handle `down` key event and focus on the current tab.
    if (isDownKey(e)) {
      window.setTimeout(() => document.querySelector(target.getAttribute('href')).focus(), 0);
      e.preventDefault();
    }

  };


  return (
    <section
      className={styles.root}
      data-count={entries.length}
      data-distribute={distribute}
      data-tablist=""
      data-variant={variant}
      {...props}
    >

    <ul className={styles.root__tablist} role="tablist" data-tabs>
      {entries.map(({ label, count }, index) => (
        <li role="presentation" key={`tab-${id}-${index}`}>
          <a
            role="tab"
            data-index={index}
            href={`#tabpanel-${id}-${index}`}
            id={`tab-${id}-${index}`}
            aria-selected={index === selectedIndex}
            tabIndex={index === selectedIndex ? 0 : -1}
            onClick={tabClickHandler}
            onKeyDown={tabKeyDownHandler}
          >
            <span>{label}</span>
            {count ? <strong>{count}</strong> : ''}
          </a>
        </li>
      ))}
    </ul>

    {showSearch ? (
      <section className={styles.root__extra}>
        <SearchFilter
          id={id}
          onSearch={onSearch}
          onDataSort={onDataSort}
          onFilterOptionSelect={onFilterOption}
        />
        <div
          className={styles.root__title}
          data-user-is-admin={user.is_approver || user.is_preparer}
          data-is-editing={isEditing}
          data-variant={variant}
        >
          {entries[selectedTab]?.entries.length ? headerColumns.map((title: string, index: number) => (
            <span key={`title-${title}-${index+20}`}>{title}</span>
          )) : null}
        </div>
      </section>
    ) : null}

    {Children.map(children, (child, index) => (
      <section
        className={styles.root__tabs}
        role="tabpanel" id={`tabpanel-${id}-${index}`}
        aria-labelledby={`tab-${id}-${index}`}
        key={`tabpanel-${id}-${index}`}
        hidden={index !== selectedIndex}
        tabIndex={index === selectedIndex ? 0 : -1}
        data-tabpanel
      >
        {child}
      </section>
    ))}

  </section>
  );

};

export default TabList
