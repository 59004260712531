import { ChangeEvent, Fragment, useCallback, useMemo, useState } from "react";
import { FormEditInput, FormGroup, IconComponent, DatePicker } from "components";
import { EditComponentProps } from "../interfaces";
import { normalizeImageUrl } from "helpers/utils";

import styles from './ClientDetailsEditTab.module.scss';

const TODAY = new Date();
const TODAY_ISO = TODAY.toISOString().split("T")[0];

const ClientEditDetailsTab = ({ selectedIndex, project }: EditComponentProps) => {

  /**
   * Compose avatar image.
   */
  const clientLogo = useMemo(
    () => normalizeImageUrl(project.logo).startsWith('data:image/jpeg;base64,') ? normalizeImageUrl(project.logo) : `data:image/jpeg;base64,${normalizeImageUrl(project.logo)}`,
    [project.logo],
  );

  return (
    <section className={styles.root__client_edit_details} data-hidden={!(selectedIndex === 1)} data-create-content>
      <article data-logo>
        <span>Company logo</span>
        <div data-client-logo>
          {project.logo ? (
            <img src={`${clientLogo}`} alt="Client Logo" />
          ) : <UploadLogo />}
        </div>
        <p className={styles.root__logo_text}>You can upload a JPG or PNG file. The max file size is 10mb.</p>
        <p className={styles.root__logo_text}>*Please make sure the client has approved the use of their logo.</p>
      </article>
      <FormGroup data-form-inputs data-edit-group="client_details">
        <FormEditInput
          name="name"
          type='text'
          label='project name'
          defaultValue={project?.name ?? ''}
          placeholder='Type your project name here'
          required
        />
        <FormEditInput
          name="description"
          type='text'
          label='project description'
          defaultValue={project?.description ?? ''}
          placeholder='Write a project description here'
          maxLength={500}
          rows={9}
          isTextArea
        />
        <FormEditInput
          name="domain"
          type='text'
          label='user email domain'
          subLabel={`Set up the client email domain, so that all the users within this domain get access to this client <br/>environment <strong data-email-domain>eg. @pwc.com</strong>`}
          defaultValue={project?.domain ?? ''}
          placeholder='@pwc.com'
          data-user-email-domain
          required
        >
          <span data-domain>@</span>
          <span>
            How will this domain be used? Based on the input provided regarding the clients domain, all the users that log in to the platform using that domain, will have access to this client environment. eg. if the domain is @pwc.com, all the pwc employees that log in using the PwC SSO will be redirected to the PwC legislation environment
          </span>
        </FormEditInput>

        <DatePicker
          name="starting_date"
          label="starting date of engagement"
          defaultValue={project?.starting_date || TODAY_ISO || `${new Date().toISOString()}`}
          required={false}
          onChange={(date: string) => console.log(date)}
        />
      </FormGroup>
    </section>
  );

};

export default ClientEditDetailsTab;

const UploadLogo = () => {
  const [file, setFile] = useState<File[]>([]);

  /**
   * Handles the files
   */
  const handleFiles = useCallback((file: File[]) => {
    for (let i = 0, numFiles = file?.length; i < numFiles; i++) {
      const _file = file[i];
      setFile(() => [_file]);
    }
  }, []);


  /**
   * Handles any drop change
   * @param event
   */
  const handleChange = function (event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    if (event.target.files && event.target.files[0]) {
      handleFiles(Array.from(event.target.files));
    }
  };

  return (
    <div className={styles.root__upload}>
      {file.length > 0 ? (
        <div>
          <img src={URL.createObjectURL(file[0])} alt="Client Logo" />
        </div>
      ) : (
       <Fragment>
         <label htmlFor="upload-logo">
            <IconComponent name="UploadIcon" />
            <p>Click here to upload company logo.</p>
          </label>
          <input
            id="upload-logo"
            type="file"
            name="logo"
            accept=".jpg, .jpeg, .png"
            onChange={handleChange}
          />
       </Fragment>
      )}
    </div>
  );

};
