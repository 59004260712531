import { Children, Fragment, useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { uuid } from '@grrr/utils';
import { getFormValues } from 'helpers/utils';
import { formatProjectPayload } from 'helpers/projects/projects';

import {
  Button, IconComponent,
  Form, Loader, TopContent,
  ProjectCard, Modal, Tabs, ButtonSet,
} from 'components';
import { ClientDetails, AdminSetup } from './Components';

import useQueryApi from 'hooks/useQueryApi';
import { useAppStateContext, useLegislationContext, useUserContext, useProjectContext } from 'contexts';
import { withLegislationHocs } from 'contexts/LegislationContext';
import { withProjectHocs } from 'contexts/ProjectContext';
import { CREATE_CLIENT_PROJECT, CREATE_CLIENT_PROJECT_LOGO } from 'configs/api-endpoints';
import { DEFAULT_APPROVED_FILTER } from 'configs/legislation/legislation';
import { Category } from 'hooks/interfaces/legislation.interface';

import EmptyProjects from './EmptyProjects';
import {
  PROJECT_CREATE_TABS,
  PROJECTS_CREATE_MODAL,
  PROJECT_CREATE_FORM_ID,
} from 'configs/project/project';
import { Client, Project } from 'pages/ProjectEdit/EditTabs/interfaces';
import styles from './Projects.module.scss';


const Projects = () => {
  const navigate = useNavigate();

  const tabRef = useRef<HTMLUListElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  // Unique IDs to be used
  const generalFormId = useMemo(() => uuid(), []);
  const id = useMemo(() => uuid(), []);

  const { user } = useUserContext();
  const { state } = useLegislationContext();
  const { showToast } = useAppStateContext();
  const { state: projectState, isLoading } = useProjectContext();

  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [formValues, setFormValues] = useState<any>({});
  const [selectedFilters, setSelectedFilters] = useState<Category>({ ...DEFAULT_APPROVED_FILTER });
  const [teamMembers, setTeamMembers] = useState<Client[]>([]);
  const [owners, setOwners] = useState<string[]>([]);
  const [direction, setDirection] = useState<'next' | 'previous' >('next');


  const { post: createClientProject } = useQueryApi(CREATE_CLIENT_PROJECT);
  const { post: createClientLogo } = useQueryApi(CREATE_CLIENT_PROJECT_LOGO);
  const { data, mutate: createProject, isError, isSuccess } = createClientProject();
  const { data: logoData, mutate: createLogo, isError: isLogoError } = createClientLogo();


  /**
   * Handle tab click.
   */
  const tabClickHandler = (index: number) => {
    if (selectedIndex > Number(index)) {
      setDirection('previous');
    } else {
      setDirection('next');
    }
    setSelectedIndex(Number(index));
  };


  /**
   * Mange the team members added to the project payload
   * @param teamMembers
   */
  const updateTeamMembers = (teamMembers: Client[]) => {
    setTeamMembers(teamMembers);
  };


  /**
   * Update the filters when the user selects a filter.
   * @param filters
   * @param event
  */
  const updateFilters = (event: any, isInputChecked: boolean) => {
    if (!event?.target) return;

    const input = event.target?.previousElementSibling?.querySelector('input');
    const isCustomCheckbox = input?.dataset.customCheckbox;
    const category = isCustomCheckbox ? 'issuing_jurisdiction' : event.target.dataset.category;
    const identifier = isCustomCheckbox && input
      ? state?.filters?.find(f => f.label === category)?.data?.find(d => d.name === input?.value)?.identifier
      : event.target.dataset.identifier;

    // for the custom checkboxes
    if (input?.type === 'checkbox' && isCustomCheckbox) {
      if (isInputChecked && identifier) {
        if (!selectedFilters[category as keyof Category].includes(identifier)) {
          setSelectedFilters((prev) => ({
            ...prev,
            [category]: [...prev[category as keyof Category], identifier]
          }));
        }
      }

      if (!isInputChecked && identifier) {
        setSelectedFilters((prev) => ({
          ...prev,
          [category]: prev[category as keyof Category].filter((id: string) => id !== identifier)
        }));
      }
      return;
    }

    // for the default checkboxes
    if (event.target.type === 'checkbox' && !isCustomCheckbox && identifier) {
      if (category && (event.target.checked)) {
        if (!selectedFilters[category as keyof Category].includes(identifier)) {
          setSelectedFilters((prev) => ({
            ...prev,
            [category]: [...prev[category as keyof Category], identifier]
          }));
        }
      }
      if (!(event.target.checked)) {
        setSelectedFilters((prev) => ({
          ...prev,
          [category]: prev[category as keyof Category].filter((id: string) => id !== identifier)
        }));
      }
      return;
    }
  };


  /**
   * Handle Form input changes.
   */
  const onFormInputChange = (event: any, isChecked: boolean) => {
    if (!formRef.current) return;

    // Update filters
    updateFilters(event, isChecked);
    const formData = Object.fromEntries(new FormData(formRef.current).entries());
    setFormValues((prev: any) => ({ ...prev, ...formData }));
  };

  // console.log({ owners, teamMembers });



  /**
   * Update the form values when the special checkbox changes.
   * @param e
   * @param isChecked
   */
  const onCheckboxChangeHandler = (e: any, isChecked: boolean) => {
    onFormInputChange(e, isChecked);
  }


  /**
   * Update form values when team members changes.
   */
  useEffect(() => {
    if (isFormUpdated) {
      onFormInputChange(undefined, false);
      setIsFormUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormUpdated]);


  /**
   * Handle form submission.
   */
  const formSubmitHandler = (e: any) => {
    e.preventDefault();

    if (!Object.keys(formValues).length) {
      return;
    }

    let payload: {
      name: any;
      description: any;
      domain: any;
      starting_date: any;
      team_member_list?: string[];
      project_owner_list?: string[];
    } = {
      name: formValues.name,
      description: formValues.description,
      domain: formValues.domain,
      starting_date: formValues.starting_date,
    }

    const members = teamMembers.filter((member) => ['preparer', 'approver'].includes(member.role.toLowerCase())).map((m) => m.identifier);
    const owners = teamMembers.filter((member) => member.role.toLowerCase() === 'project owner').map((m) => m.identifier);

    if (members.length) {
      payload = {
        ...payload,
        team_member_list: members,
      }
    }

    if (owners.length) {
      payload = {
        ...payload,
        project_owner_list: owners,
      }
    }


    // TODO: Bring this back again later
    // if (formValues.logo instanceof File) {
    //   const formData = new FormData();
    //   formData.append('logo', formValues.logo);
    //   createLogo(formData);
    // }
    createProject(payload);
    // Get payload data
  };


  /**
   * Handle the project creation success or error.
   */
  useEffect(() => {
    if (isSuccess) {
      showToast({
        title: 'Project Created Successfully',
        message: `Please take not that project has been created successfully but can still be updated/edited by an admin.`,
        type: 'message',
        active: true,
        persistent: false,
      });
      navigate(`/projects/${data.identifier}/edit`);
    }

    if (isError) {
      showToast({
        title: 'Something went wrong',
        message: `Please be informed the project could not be created, try again later.`,
        type: 'error',
        active: true,
        persistent: true,
      });
    }

    setIsCreating(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isError, isSuccess]);


  /**
   * Handle going next in the project creation steps/tabs.
   */
  const paginationHandler = (e: any, d: 'next' | 'previous') => {
    e.preventDefault();
    setDirection(d);

    if (d === 'next') {
      if (selectedIndex === 3) {
        return;
      }
      setSelectedIndex(selectedIndex + 1);
    } else if (d === 'previous') {
      if (selectedIndex === 1) {
        return;
      }
      setSelectedIndex(selectedIndex - 1);
    }
  };


  return (
    <Fragment>
      {/* Top Content */}
      <TopContent isDetails>
        <Fragment>
          <h3>Projects</h3>
          <div data-add-btn>
            <p>Create tailored environments by selecting the sustainability legislations relevant to each client.</p>
            {user.is_admin ? (
              <Button variation='primary' onClick={() => setIsCreating(true)}>
              <IconComponent name="PlusFillIcon" />
              <span>New project</span>
            </Button>
            ) : null}
          </div>
        </Fragment>
      </TopContent>

      {/* Main Content */}
      <section className={styles.root} data-main-content>
        {!projectState.projectGroups?.length && !isLoading ? (
          <EmptyProjects />
        ) : isLoading ? (
          <Loader data-medium />
        ) : (
          <article className={styles.root__projects}>
            {projectState.projectGroups?.length ? Children.toArray(projectState.projectGroups?.map((group, index: number) => (
              <Fragment key={`${group.label}-${index}`}>
                <h3 className={styles.root__project_status}>{group.label}</h3>
                <ul className={styles.root__list} key={`${group.label}-${index}`} data-is-empty={group.projects.length === 0}>
                  {group.projects.length ? group.projects.map((project: Project) => (
                    <ProjectCard
                      key={project.identifier}
                      project={project}
                    />
                  )) : (
                    <div>
                      {isLoading ? <Loader data-medium /> : (
                        <span>There are currently no project for the <strong><em>{group.label}</em></strong> group.</span>
                      )}
                    </div>
                  )}
                </ul>
              </Fragment>
            ))) : null}
          </article>
        )}
      </section>

      {isCreating ? (
        <Modal
          id={PROJECTS_CREATE_MODAL}
          isOpen={isCreating}
          onOpen={() => setIsCreating(true)}
          onClose={() => setIsCreating(false)}
          data-project-create
        >
          <header data-project-create-header>
            <Button
              variation='transparent'
              onClick={() => setIsCreating(false)}
              size='small'
            >
              <IconComponent name="CloseOutlineIcon" />
            </Button>
            <div>
              <h2>Create new project</h2>
              <p>Provide general details about the project. You can alter these after the project has been created.</p>
              <span data-required>Required Fields</span>
            </div>
          </header>

          <Tabs
            tabSelected={selectedIndex}
            options={PROJECT_CREATE_TABS}
            onTabClick={tabClickHandler}
            data-tabs
            ref={tabRef}
          />

          <Form
            id={`${PROJECT_CREATE_FORM_ID}-${generalFormId}`}
            data={{}}
            onSubmit={formSubmitHandler}
            onChange={onFormInputChange}
            ref={formRef}
          >
            {/* Client Details */}
            <ClientDetails selectedIndex={selectedIndex} direction={direction} />

            {/* Admin/Team setup */}
            <AdminSetup
              selectedIndex={selectedIndex}
              updateMembers={updateTeamMembers}
              setIsFormUpdated={setIsFormUpdated}
            />
          </Form>

          {/* Action Buttons */}
          <ButtonSet data-btn-set>
            <Button
              variation="transparent"
              onClick={(event: any) => {
                selectedIndex === 1
                  ? setIsCreating(false)
                  : paginationHandler(event, 'previous');
              }}
              disabled={false}
            >
              {selectedIndex === 1 ? 'Cancel' : 'Previous'}
            </Button>
            {selectedIndex < 2 ? (
              <Button
              type='button'
              onClick={(event: any) => paginationHandler(event, 'next')}
              value="Submit"
              // disabled={false}
            >
              {selectedIndex < 3 ? 'Next' : 'Submit'}
            </Button>
            ) : (
              <Button
                type="submit"
                aria-controls={`${PROJECT_CREATE_FORM_ID}-${generalFormId}`}
                form={`${PROJECT_CREATE_FORM_ID}-${generalFormId}`}
                value="Submit"
                disabled={false}
              >
                Submit
              </Button>
            )}
          </ButtonSet>
        </Modal>
      ) : null}
    </Fragment>
  );

};

export default withProjectHocs(withLegislationHocs(Projects));
