import React from "react";
import { NavLink } from "react-router-dom";
import { highlight } from "helpers/utils";
import { getRelativeDateTime } from "helpers/dateTime";

import { Legislation, UserInterface } from "hooks/interfaces";
import { ConfigLegislation } from "hooks/interfaces/project.interface";
import { Button, ButtonSet, IconComponent } from "components/index";

interface Props {
  configLeg: ConfigLegislation;
  user: UserInterface;
  query: string;
  setIsEditing: (isEditing: boolean) => void;
  setCurrentEditCard: (legislation: Legislation) => void;
  [key: string]: any;
}

const Card = ({
  user,
  query,
  configLeg,
  setIsEditing,
  setCurrentEditCard,
  ...props
}: Props) => {

  /**
   * Get the human readable relative date
   * @param legislation
   * @returns
   */
  const getRelativeDate = (date: string) => {
    if (!date) return '';

    const { value, label } = getRelativeDateTime(date || '', { includeTime: true });
    return `${value} ${label} ago`;
  };

  return (
    <li data-legislation-card {...props}>
      <NavLink
        to={`#!`}
        title={`See client role-specific content for: ${configLeg.legislation.name_local}`}
        aria-label={`See client role-specific content for: ${configLeg.legislation.name_local}`}
        onClick={() => {
          if (configLeg.is_selecting) return;
          setIsEditing(true);
          setCurrentEditCard(configLeg.legislation)
        }}
        >
          <div>
            <h5
              dangerouslySetInnerHTML={{
                __html: highlight(configLeg.legislation.name_generic  === 'nan' ? configLeg.legislation.name_local : configLeg.legislation.name_generic, query)
              }}
            ></h5>
            <span>
              <small
              dangerouslySetInnerHTML={{
                __html: `Checked ${getRelativeDate(configLeg.created_at)} &nbsp;`
              }}
              ></small>
            </span>
          </div>
          {/* {configLeg.is_selecting ? ( */}
            <span data-history>
            <small>
              {configLeg.is_approved ? `Approved: ${getRelativeDate(configLeg.created_at)}` : 'No review history yet'}
            </small>
          </span>
          {/* ) : <>&nbsp;</>} */}
          <ButtonSet>
            {!configLeg.is_selecting ? (
              <Button
              variation='tertiary'
              size="small"
              aria-label="Edit Legislation"
              title={`See client role-specific content for: ${configLeg.legislation.name_generic}`}
              onClick={() => {
                setIsEditing(true);
                setCurrentEditCard(configLeg.legislation)
              }}
              data-edit-btn
            >
              Edit
              <IconComponent name="RightChevron" />
            </Button>
            ) : <>&nbsp;</>}
          </ButtonSet>
      </NavLink>
    </li>
  );

};

export default Card;
