import { Children, Fragment, useEffect, useMemo, useState } from "react";

import {
  Button, ButtonSet, EmptyLegislationList,
  IconComponent, Loader, Modal, TabList,
} from "components";
import { useQueryApi } from "hooks/index";
import { REQUEST_CLIENT_LEGISLATION_APPROVAL } from "configs/api-endpoints";

import { EMPTY_ATTRIBUTES } from "configs/project/project";

import { Legislation, UserInterface } from "hooks/interfaces";
import { Filter } from "hooks/interfaces/legislation.interface";
import { EditComponentProps, LegislationTabs, SelectedAttributes } from "../interfaces";
import { ConfigLegislation } from "hooks/interfaces/project.interface";
import { JURISDICTION_KEY } from "configs/legislation/legislation";
import Aside from "./ConfigAside";
import Card from './Card';

import styles from './ContentConfig.module.scss';


const ContentConfigurationEditTab = ({
  project,
  selectedIndex,
  clientLegislations,
  isPending,
  updateFilters,
  canRequestApproval = false,
  setCurrentEditCard,
  setIsEditing,
  isEditing,
  user,
  filters = [],
  showToast,
}: EditComponentProps & {
  clientLegislations: ConfigLegislation[];
  isPending: boolean;
  updateFilters: (checked: boolean, filterOption: Filter, name: string) => void;
  viewState?: "viewing" | "editing";
  canRequestApproval: boolean;

  setCurrentEditCard: (legislation: Legislation) => void;
  setIsEditing: (isEditing: boolean) => void;
  isEditing: boolean;
  showToast: any;
  user: UserInterface;
}) => {

  const [legislationTabs, setLegislationTabs] = useState<LegislationTabs[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [attributesCleared, setAttributesCleared] = useState<boolean>(false);
  const [clearAll,  setClearAll] = useState<boolean>(false);
  const [allSelectedAttributes, setAllSelectedAttributes] = useState<SelectedAttributes>(EMPTY_ATTRIBUTES);
  const [query, setQuery] = useState<string>('');

  const { post: requestLegislationApproval } = useQueryApi(REQUEST_CLIENT_LEGISLATION_APPROVAL);
  const { mutate: requestApproval, isSuccess, isError, error } = requestLegislationApproval();


  /**
   * Clear all selected attributes
   */
  const onClearAllAttributesHandler = () => {
    setClearAll(false);
    setAttributesCleared(true);
    setAllSelectedAttributes(EMPTY_ATTRIBUTES);
    // setJurisdiction([]);
    // setStatus(formatFilterData(filters, STATUS_KEY, []));
    // setJobRoles(formatFilterData(filters, JOB_ROLE_KEY, []));
    // setLegislationTypes(formatFilterData(filters, TYPE_KEY, []));
    // setSustainabilityTopic(formatFilterData(filters, TOPIC_KEY, []));
    // setProductService(formatFilterData(filters, PRODUCT_SERVICE_KEY, []));
    // setGeographicalScope(formatFilterData(filters, SCOPE_KEY, []));
  };


  /**
   * Handle pill change event
   */
  const onPillChange = (e: any) => {
    if (!e.target) return;
    const category = e.target.dataset.category;
    const identifier = e.target.dataset.identifier;
    const checked = e.target.checked;

    const filter = filters.find((f) => f.label === category);
    if (!filter) return;

    const filterName = filter.data.find((d) => d.identifier === identifier)?.name;
    updateFilters(checked, filter, filterName || '');
  };


  /**
   * Handle Checkbox change event
   */
  const onCheckboxChange = (event: any, isChecked: boolean) => {
    if (!event) return;

    const inputElement = event.target?.previousElementSibling?.querySelector('input');
    const filter = filters.find((f) => f.label === JURISDICTION_KEY);
    const value = inputElement?.value;

    // Check if the filter already exist in the list or not
    const filterExist = filter?.data.find((d) => d.name.toLowerCase() === value.toLowerCase());

    if (filterExist) {
      updateFilters(isChecked, filter as Filter, value);
    }
  };


  /**
   * Send and lock legislations to the client project
   */
  const onStartReviewProcessHandler = () => {
    const allLegislationIds = clientLegislations.map((l) => l.legislation.identifier);
    const payload = {
      client_identifier: project.identifier,
      legislation_identifier_list: allLegislationIds,
    };

    requestApproval(payload);
  };


  /**
   * Show success message to the client on successful request approval
   */
  useEffect(() => {
    if (isSuccess) {
      showToast({
        type: 'message',
        title: 'Review process started',
        message: 'The review process has been initiated for the selected legislations.',
        active: true,
        persistent: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, project.identifier, isEditing]);


  /**
   * Show the clear all selected attributes button
   */
  const showClearAllButton = useMemo(() => {
    return Object.values(allSelectedAttributes).some((attribute) => attribute.data.length > 0);
  }, [allSelectedAttributes]);


  /**
   * Enable the review process button
   */
  const enableReviewProcessActionButton = useMemo(() => {
    return clientLegislations?.length > 0;
  }, [clientLegislations?.length]);


  /**
   * Group legislations by tabs
   */
  useMemo(() => {
    const list: ConfigLegislation[] = clientLegislations || [];

    const tabs = [
      {
        label: 'In Review',
        entries: list.filter((l) => !l.is_approved),
        count: list.filter((l) => !l.is_approved).length,
      },
      {
        label: 'Approved',
        entries: list.filter((l) => l.is_approved),
        count: list.filter((l) => l.is_approved).length,
      },
    ];
    setLegislationTabs(tabs);
  }, [clientLegislations]);


  return (
    <section className={styles.root} data-hidden={!(selectedIndex === 3)} data-create-content data-content-configuration>
      {/* Aside Filter Content */}
      <Aside
        filters={filters}
        showClearAllButton={showClearAllButton}
        onPillChange={onPillChange}
        onCheckboxChange={onCheckboxChange}
        setClearAll={setClearAll}
        allSelectedAttributes={allSelectedAttributes}
      />

      <article data-legislation-content>
        <header>
          <div>
            <h6>
              {!canRequestApproval
                ? 'Configured legislation list'
                : 'Matching legislation to attributes'
              } &nbsp;| <span>{clientLegislations.length || 0} results</span>
            </h6>
            {canRequestApproval ? (
              <Button
                variation="secondary"
                size="small"
                type="button"
                onClick={onStartReviewProcessHandler}
                disabled={!clientLegislations.length}
              >
                Start review process
                <IconComponent name="DownIcon" />
              </Button>
            ) : null}
          </div>
        </header>

        <div data-legislation-list>
          <TabList
            showSearch
            user={user}
            query={query}
            onSearch={() => {}}
            variant="project-tabs"
            onDataSort={() => {}}
            isEditing={isEditing}
            selectedTab={selectedTab}
            onFilterOption={() => {}}
            entries={legislationTabs}
            setSelectedTab={setSelectedTab}
          >
          {legislationTabs.map((tab, index) => (
              <Fragment key={index}>
                {isPending ?  <Loader data-details /> : (
                  <ul data-tab-list>
                    {Children.toArray(tab.entries.length ? tab.entries.map((configLeg: any, _i: number) => (
                      <Card
                        user={user}
                        query={query}
                        configLeg={configLeg}
                        setIsEditing={setIsEditing}
                        setCurrentEditCard={setCurrentEditCard}
                      />
                    )) : (
                      <EmptyLegislationList showContent={!isPending && tab.entries.length === 0} query={query} />
                    ))}
                  </ul>
                )}
              </Fragment>
            ))}
          </TabList>
          <EmptyLegislationList query="" showContent={legislationTabs?.length <= 0} data-empty/>
        </div>
      </article>

      {clearAll ? (
        <Modal
          id={'clear-all-selected-attributes'}
          isOpen={clearAll}
          onOpen={() => setClearAll(true)}
          onClose={() => setClearAll(false)}
          data-upload-content
        >
          <header data-project-create-header>
            <Button
              variation='transparent'
              onClick={() => setClearAll(false)}
              size='small'
            >
              <IconComponent name="CloseOutlineIcon" />
            </Button>
            <div>
              <h2>Remove attributes</h2>
            </div>
          </header>
          <p>
            By removing attributes for <strong>{project.name}</strong>, all the associated legislations to this attributes will disappear for the project regardless of their review status (created, in review or published). Are you sure you want to remove the attributes and exclude [number of legislations] from the project?
          </p>

          {/* Action Buttons */}
          <ButtonSet data-btn-set>
            <Button
              variation="cancel"
              onClick={() => setClearAll(false)}
              disabled={false}
            >
              No, keep attributes
            </Button>
            <Button
              type='button'
              onClick={onClearAllAttributesHandler}
              value="button"
              disabled={false}
            >
              Yes, remove attributes
            </Button>
          </ButtonSet>
        </Modal>
      ) : null}
    </section>
  );

};

export default ContentConfigurationEditTab;
