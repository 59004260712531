import { useState, useEffect, useLayoutEffect } from 'react';
import  { extractRequirements } from 'helpers/utils';
import { Accordion, AccordionItem } from 'components/index';
import { Legislation } from 'hooks/interfaces';
import styles from '../LegislationDetails.module.scss';

interface DetailsTabProps {
  showContent?: boolean;
  legislation: Legislation;
}

const REQUIREMENT_KEYS = ["registration_requirements", "regulatory_requirements", 'reporting_requirements'];

const LegislationDetailsTab = ({ legislation }: DetailsTabProps) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const [requirements, setRequirements] = useState<any[]>([]);

  /**
   * Set the legislation requirements as a flat array
   */
  useEffect(() => {
    if (!legislation) {
      setRequirements([]);
      return;
    }

    setRequirements(extractRequirements(legislation, REQUIREMENT_KEYS) || []);
  }, [legislation]);


  /**
   * Handles accordion click event
   * @param activeKeys
   */
  const onClickAccordion = (activeKeys: string[]) => {
    setActiveKeys(activeKeys);
  };


  /**
   * Open the first accordion when the details tab is active
   */
  useLayoutEffect(() => {
    if (requirements.length) {
      setActiveKeys([requirements?.at(0)?.data[0]?.identifier || '']);
    }
  }, [requirements, requirements.length]);


  if (!legislation) {
    return null;
  }

  return (
    <article className={styles.root__article} data-content>

      <div className={styles.root__detailrow}>
        <div className={styles.root__detailheader}>
          <span data-title>Type</span>
          <span data-title>Name of Regulations</span>
          <span data-title>Abbreviation</span>
          <span data-title>Name in Local Language</span>

          <span data-subtitle>{legislation?.geographical_scope?.at(0)?.name || legislation.scope || 'No scope provided'}</span>
          <span data-subtitle>{legislation.name_generic}</span>
          <span data-subtitle>{legislation.abbreviation}</span>
          <span data-subtitle>{legislation.name_local}</span>
        </div>
      </div>

      <div className={styles.root__detailrow}>
        <div className={styles.root__detailheader}>
          <span data-title>In effect</span>
          <span data-title>Geographical scope</span>
          <span data-title>Applicable from</span>
          <span data-title>Applicable to</span>

          <span data-subtitle>{legislation.is_in_effect ? 'Yes' : 'No'}</span>
          <span data-subtitle>{legislation?.geographical_scope?.at(0)?.name || legislation.scope || 'No scope provided'}</span>
          <span data-subtitle>{legislation.effective_date}</span>
          <span data-subtitle>{legislation.effective_until || 'Unknown'}</span>
        </div>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>Sustainability topic</span>
        <ul>
          {legislation.topic?.map((_t, _i) => (
            <li key={`topic-${_t.name}-${_i}`}>{_t.name}</li>
          ))}
        </ul>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>link</span>
        <ul>
          <li data-link>
            <a href={`${legislation.link || '#'}`} target="_blank" rel="noreferrer">{legislation.link || ''}</a>
          </li>
        </ul>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>link to additional guidance</span>
        <ul>
          {legislation.additional_links ? (
              <li>{legislation.additional_links}</li>
            ) : (
              <li>{'No additional link found'}</li>
            )}
        </ul>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>"issuing" jurisdiction</span>
        <ul>
          {legislation.issuing_jurisdiction?.map((_i, index) => (
            <li key={`issuing_jurisdiction-${_i.name}-${index}`}>{_i.name}</li>
          ))}
        </ul>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>responsible authority</span>
        <span data-subtitle>{legislation.responsible_authority}</span>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>products</span>
        <ul>
        {legislation.product_service?.map((_i, index) => (
            <li key={`product_service-${_i.name}-${index}`}>{_i.name}</li>
          ))}
        </ul>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>region</span>
        <span data-subtitle>{legislation.region || 'No region found'}</span>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>objective of the legislation (summary)</span>
        <p>{legislation.summary}</p>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>responsible party (summary)</span>
        <p>{legislation.responsible_party || "No responsible party summary provided for this legislation."}</p>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>objective of the legislation (summary)</span>
        <p>{legislation.objective || 'No objective of this legislation provided for this legislation.'}</p>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>scope of the legislation (summary)</span>
        <p>{legislation.scope_summary || "No scope summary provided for this legislation."}</p>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>consequences of non-compliance</span>
        <ul>
          {legislation.non_compliance_consequence?.map((_i, index) => (
            <li key={`non_compliance_consequence-${_i.name}-${index}`}>{_i.name}</li>
          ))}
        </ul>
      </div>

      <div className={styles.root__detailrow}>
        <span data-title>Requirement type</span>

        {requirements.length ? <Accordion
          multiple={false}
          onClick={onClickAccordion}
          activeKeys={activeKeys}
          data-role-view
          data-requirement-view
          accordionId={'navDetails?.identifier'}
        >
          {requirements?.map((requirement: any, index: number) => {
            return requirement.data.map((currentRequirement: any) => (
              <AccordionItem
                key={`requirement-${currentRequirement.identifier}-${index}`}
                contentTitle={requirement.label}
                description={requirement.description}
                itemKey={currentRequirement.identifier}
                isLarge
                data-role-view
              >
                <RequirementAccordion requirement={currentRequirement} />
              </AccordionItem>
            ))
          })}
        </Accordion> : null }
      </div>
    </article>
  )
}

export default LegislationDetailsTab


const RequirementAccordion = ({ requirement }: any) => {
  const requirementData = requirement || [];
  const elements = requirement?.data_elements || '';
  let formattedText = requirement?.trigger
    .replace(/(\d+\.\s)/g, '<br />$1')
    .replace(/;\s*(<br \/>|$)/g, '.<br />');

  // Remove the leading <br /> tag if it exists
  if (formattedText && formattedText?.startsWith('<br />')) {
    formattedText = formattedText.replace(/^<br \/>/, '');
  }

  if (!Object.keys(requirementData).length) {
    return (
      <article data-accordion data-empty-content>
        <span data-intro>
          Sorry but there is no data provided for this requirement yet.
        </span>
      </article>
    );

  }

  return (
    <article data-accordion>
      <span data-intro>
        Refer to the process of officially enrolling or recording certain information with a relevant authority or organization. It typically involves providing specific details about an individual, entity, or activity to establish legal recognition or compliance.
      </span>

      <div className={styles.root__detailrow}>
        <span data-title>general description</span>
        <p>{requirementData?.description || ''}</p>
      </div>
      <div className={styles.root__detailrow}>
        <span data-title>responsible authority</span>
        <p>{requirementData?.responsible_authority || ''}</p>
      </div>
      <div className={styles.root__detailrow}>
        <span data-title>responsible party</span>
        <p>{requirementData?.responsible_party || ''}</p>
      </div>
      {elements ? (
        <div className={styles.root__detailrow}>
          <span data-title>data elements required</span>
          {Array.isArray(elements) ? (
            <ul>
              {elements.length ? elements?.map((_i, index) => (
                <li key={`elements_${_i}-${index}`}>{_i}</li>
              )) : null}
            </ul>
          ) : (
            <p>{elements || ''}</p>
          )}
        </div> ) : null}
      <div className={styles.root__detailrow}>
        <span data-title>payment obligations</span>
        <p>{requirementData?.payment_obligations || ''}</p>
      </div>
      <div className={styles.root__detailrow}>
        <span data-title>deadline</span>
        <p>{requirementData?.deadline || ''}</p>
      </div>
      <div className={styles.root__detailrow}>
        <span data-title>threshold</span>
        <p>{requirementData?.threshold || ''}</p>
      </div>
      <div className={styles.root__detailrow}>
        <span data-title>sanctions</span>
        <p>{requirementData?.sanctions || ''}</p>
      </div>
      <div className={styles.root__detailrow}>
        <span data-title>exemptions</span>
        <p>{requirementData?.exemptions || ''}</p>
      </div>
      <div className={styles.root__detailrow}>
        <span data-title>trigger</span>
        <p dangerouslySetInnerHTML={{ __html: formattedText }} />
      </div>
    </article>
  );

};
