import { Fragment, useMemo, useState, useEffect, useLayoutEffect } from 'react';
import { useParams, useOutletContext  } from 'react-router-dom';
import { uuid } from '@grrr/utils';
import  { formatRoleSpecificData } from 'helpers/legislations/legislation';

import { TopContent, BackLink, Button, Modal } from 'components';
import EditLegislation from 'pages/Legislation/Admin/EditContent/EditLegislation';
import { useAppStateContext, useLegislationContext } from 'contexts';
import { withLegislationHocs } from 'contexts/LegislationContext';

import { useQueryApi } from 'hooks';
import { GET_LEGISLATION_JOB_ROLE, GET_LEGISLATIONS_BY_ID } from 'configs/api-endpoints';
import { FILTER_TABS, DESCRIPTION, LEGISLATION_DETAILS_EDIT_MODAL_ID, JOB_ROLE_KEY, SELECT_ALL_FILTER_KEY } from 'configs/legislation/legislation';
import { RolesTab, DetailsTab, ContactsTab } from './Tabs';
import { OutletContextProps } from '../Legislation/interfaces';
import { Legislation } from 'hooks/interfaces';

import Loading from 'pages/Loading/Loading';
import styles from './LegislationDetails.module.scss';
import { Filter, FilterOption } from 'hooks/interfaces/legislation.interface';
import { RoleInterface } from 'components/Onboarding/Onboarding.interface';


const LegislationDetails = () => {

  const { legislationId } = useParams();
  const id = useMemo(() => uuid(), []);

  const { user } = useOutletContext<OutletContextProps>();
  const { showToast } = useAppStateContext();
  const { state, stateDispatch: dispatch, stateActions, refetch } = useLegislationContext();

  // State data
  const [jobRoleData, setJobRoleData] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [selectedNavIndex, setSelectedNavIndex] = useState('');
  const [currentLegislation, setCurrentLegislation] = useState<Legislation>();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const isFirstTab = FILTER_TABS?.at(0)?.identifier === selectedIndex;
  const isLastTab = FILTER_TABS?.at(-1)?.identifier === selectedIndex;

  // Get the legislation job role
  const { get: getLegislationJobRole } = useQueryApi({
    ...GET_LEGISLATION_JOB_ROLE,
    endpoint: `${GET_LEGISLATION_JOB_ROLE.endpoint}${legislationId}/attention-points/`,
  });
  const { get: getLegislationDetails } = useQueryApi({
    ...GET_LEGISLATIONS_BY_ID,
    endpoint: `${GET_LEGISLATIONS_BY_ID.endpoint}${legislationId}`
  });

  const { data: jobRoleResponse, error: jobRoleError, isLoading: isjRLoading } = getLegislationJobRole(null);
  const { data: legData, error: legError, isLoading: isLLoading, refetch: refetchLegislationDetails } = getLegislationDetails(null) as { data: any, error: any, isLoading: boolean, refetch: () => void };

  // Get the job roles
  const jobRoles = useMemo(
    () => state
      .filters
      .find((f) => f.label === JOB_ROLE_KEY)
      ?.data.map((d) => ({
        ...d,
        data: jobRoleData?.find((role: any) => role.name.toLowerCase() === d.name.toLowerCase())
      })) || [],
    [jobRoleData, state.filters]
  );


  /**
   * Set the initial tab index if the selectedTab is not provided.
  */
  useLayoutEffect(() => {
    setSelectedIndex(FILTER_TABS?.at(0)!.identifier); // TODO: Set this back to the first tab (0)
  }, [state]);


  /**
   * Fetch the legislation details by id.
   * Get the legislation details by id if the legislationId is provided.
   */
  useEffect(() => {
    if (legislationId && !isLLoading && !legError && drawerIsOpen) {
      const { results } = legData;
      setCurrentLegislation(results);
    }

    if (!jobRoleData?.errors?.length && !isjRLoading && legislationId && jobRoleResponse?.results.length) {
      const { results } = jobRoleResponse;

      let formattedJobRoleData = jobRoles.map((role: any) => formatRoleSpecificData(role, results)
      ).filter((_r) => _r.data.notes.length) || [];


      if (formattedJobRoleData.length && formattedJobRoleData.length !== jobRoles.length) {
        const missingRoles = jobRoles.filter((role) => !formattedJobRoleData.find((_r) => _r.identifier === role.identifier));
        const formattedMissingData = missingRoles.map((role: any) => formatRoleSpecificData(role, []));

        formattedJobRoleData = [...formattedJobRoleData, ...formattedMissingData];
      }

      setJobRoleData(formattedJobRoleData.filter((f) => !f.identifier.includes(SELECT_ALL_FILTER_KEY)));
    } else {
      const roleIdentifiers = legData?.results.job_role_list.map((r: { identifier: string; name: string }) => r.identifier);
      setJobRoleData(
        jobRoles
        ?.filter((f) => roleIdentifiers?.includes(f?.identifier))
        ?.map((role: any) => formatRoleSpecificData(role, []))
      );
    }

    if (jobRoleData?.errors?.length > 0) {
      showToast({
        title: 'Role Content',
        message: 'No Role Content was found for this legislation.',
        persistent: true,
        active: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legislationId, isjRLoading, isLLoading]);


  /**
   * Handle tab click.
   */
  const tabClickHandler = (e: any) => {
    const index = e.currentTarget.getAttribute('data-filter-index');
    setSelectedIndex(index);
    e.preventDefault();
  };


  /**
   * Set the current legislation if it's not the same as the current one.
   */
  useEffect(() => {
    if (state.current?.identifier !== legislationId) {
      const current = state.legislations.find((_l) => _l.identifier === legislationId);
      dispatch(stateActions.seCurrentLegislation(current?.identifier));
    }

    if (legislationId) {
      setCurrentLegislation(state.legislations.find((l) => l.identifier === legislationId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legislationId, currentLegislation?.id, state.legislations, drawerIsOpen]);


  /**
   * Set the initial to the first tab index if the selectedTab is not provided.
   */
  useEffect(() => {
    if (jobRoles.length) {
      setSelectedNavIndex(jobRoles.filter((r) => r.data !== undefined)[0]?.identifier);
    }
  }, [jobRoles, jobRoles.length]);


  useEffect(() => {
    if (!drawerIsOpen) {
      refetchLegislationDetails();
    }
  }, [drawerIsOpen, refetchLegislationDetails]);

  if (isLLoading) {
    return (
      <Loading amount={4} data-details />
    );

  }


  return (
    <Fragment>

      {/* Top Content */}
      <TopContent isDetails data-details-page>
        <Fragment>
          <BackLink>Back</BackLink>
          <div className={styles.root__topcontent}>
            {currentLegislation?.name_local ? (
              <h3>{currentLegislation?.name_generic === 'nan' ? currentLegislation.name_local : currentLegislation?.name_generic}</h3>
            ) : <Loading /> }
            <span
              data-in-effect={currentLegislation?.is_in_effect}
            >
              <small className={styles.root__pulser}>&nbsp;</small>
              In effect
            </span>
          </div>
          <p>See the full details about the legislations from role specific content to summaries and PwC expert team</p>

          <ul className={styles.root__tablist} role="tablist" data-tabs>
            {FILTER_TABS.map((tab, index) => (
              <li role="presentation" key={`tab-${id}-${tab.label}-${index}`}>
                <a
                  className={styles.root__tablistlink}
                  role="tab"
                  data-filter-index={tab.identifier}
                  href={`#tabpanel-${id}-${index}`}
                  id={`tab-${id}-${tab.identifier}`}
                  aria-selected={tab.identifier === selectedIndex}
                  tabIndex={tab.identifier === selectedIndex ? 0 : -1}
                  onClick={tabClickHandler}
                  // onKeyDown={tabKeyDownHandler}
                >
                  <span>{tab.label}</span>
                </a>
              </li>
            ))}

            {user.is_admin ? <li role="presentation">
              <Button variation='primary' type='button' onClick={() => setDrawerIsOpen(true)}>
                Edit details
              </Button>
            </li> : <li>&nbsp;</li>}
          </ul>

          {/* The intro part */}
          <article className={styles.root__intro}>
            <h5>{DESCRIPTION[selectedIndex]?.label || ''}</h5>
            <p>{DESCRIPTION[selectedIndex]?.description || ''}</p>
          </article>

        </Fragment>
      </TopContent>

      {/* Main Content */}
      <section
        className={styles.root}
        data-first-tab={isFirstTab}
        data-middle-tab={!(isFirstTab || isLastTab)}
        data-main-content
        data-inverted
        data-details
      >
        {isFirstTab ? (
          <RolesTab
            showContent={isFirstTab}
            legislation={legData?.results || currentLegislation!}
            adminData={{ is_approver: user.is_approver, is_preparer: user.is_preparer }}
            jobRoleData={jobRoleData?.filter((r: FilterOption) => !r.identifier.includes(SELECT_ALL_FILTER_KEY))}
            selectedNav={selectedNavIndex}
            isEditing={false}
            filters={state.filters}
            isFirstTab={isFirstTab}
            data={currentLegislation as Legislation}
          />
        ) : null }

        {/* Details */}
        {!(isFirstTab || isLastTab) ? (<DetailsTab legislation={legData?.results || currentLegislation!} />) : null }

        {/* Contact list */}
        {isLastTab ? (<ContactsTab contacts={legData?.results?.pwc_contact || currentLegislation?.pwc_contact || []} />) : null}

      </section>

      {/* Edit Modal */}
      <Modal
        id={LEGISLATION_DETAILS_EDIT_MODAL_ID}
        isOpen={drawerIsOpen}
        onOpen={() => setDrawerIsOpen(true)}
        onClose={() => {
          setDrawerIsOpen(false);
          // setCurrentLegislation({} as Legislation);
        }}
        data-variation="drawer"
      >
      {currentLegislation?.identifier ? (
        <EditLegislation
          user={user}
          isEditing={drawerIsOpen}
          setIsEditing={setDrawerIsOpen}
          editingType="default"
          legislation={drawerIsOpen ? currentLegislation as Legislation : {} as Legislation}
          data-drawer
        />
      ) : null}
      </Modal>
    </Fragment>
  );

};

export default withLegislationHocs(LegislationDetails);
