import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { formatProjectPayload } from 'helpers/projects/projects';

import {
  BackLink, Button, ButtonSet,
  Form, IconComponent, Loader,
  Modal, Tabs, TopContent,
} from 'components';
import { ClientEditDetailsTab, AdminSetupEditTab, ContentConfigurationEditTab } from './EditTabs';

import { useQueryApi } from 'hooks/index';
import { useAppStateContext, useUserContext, useProjectContext } from 'contexts';
import { withProjectHocs } from 'contexts/ProjectContext';
import { withLegislationHocs } from 'contexts/LegislationContext';

import {
  PROJECT_DETAILS_TABLIST,
  LEGISLATION_DETAILS_ROLE_CONTENT_MODAL_ID,
  PROJECT_EDIT_FORM_ID, PROJECT_EDIT_TAB_TITLE,
} from 'configs/project/project';

import { UPDATE_CLIENT_LOGO } from 'configs/api-endpoints';
import { Legislation } from 'hooks/interfaces/legislation.interface';
import EditLegislation from 'pages/Legislation/Admin/EditContent/EditLegislation';
import styles from './ProjectEdit.module.scss';


const ProjectEdit = () => {

  const tabRef = useRef<HTMLUListElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  const navigate = useNavigate();
  const { projectId } = useParams();

  const { user } = useUserContext();
  const { showToast } = useAppStateContext();
  const {
    state: projectState,
    canPublishProject,
    canSendForApproval,
    isPending,
    updateProjectDetails,
    updateFilters,
  } = useProjectContext();

  const [selectedIndex, setSelectedIndex] = useState(1);
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>({});
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentEditCard, setCurrentEditCard] = useState<Legislation>();

  const { post: updateClientLogo } = useQueryApi(UPDATE_CLIENT_LOGO);
  const { data: logoData, mutate: updateLogo, isError: isLogoError } = updateClientLogo();


  /**
   * Handle tab click.
   */
  const tabClickHandler = (index: number) => {
    setSelectedIndex(Number(index));
  };


  /**
   * Handle Form input changes.
   */
  const onFormInputChange = (event: any, isChecked: boolean) => {
    if (!formRef.current) return;
    const formData = Object.fromEntries(new FormData(formRef.current).entries());
    setFormValues((prev: any) => ({ ...prev, ...formData }));
    // updateFilter(event, isChecked);
  };


  /**
   * Handle form submission.
   */
  const formSubmitHandler = (e: any) => {
    e.preventDefault();
    if (!formValues) return;

    // TODO: Find a way with Canberk to make this work
    // if (formValues.logo && formValues.logo instanceof File && formValues.logo.size > 0) {
    //   const formData = new FormData();
    //   formData.append('logo', formValues.logo);
    //   updateLogo(formData);
    // }

    const payloadData = formatProjectPayload(formValues);
    updateProjectDetails({ ...payloadData, is_published: true });
  };


  /**
   * Save Legislation for future editing
   * @param e
   */
  const onSaveProjectAsDraftClickHandler = (_e: any) => {
    if (!formValues) return;

    // TODO: Find a way with Canberk to make this work
    // if (formValues.logo && formValues.logo instanceof File && formValues.logo.size > 0) {
    //   const formData = new FormData();
    //   formData.append('logo', formValues.logo);
    //   updateLogo(formData);
    // }

    const payloadData = formatProjectPayload(formValues);
    updateProjectDetails({ ...payloadData, is_published: false });
  };

  /**
   * Update form values when team members changes.
   */
  useEffect(() => {
    if (isFormUpdated) {
      onFormInputChange(undefined, false);
      setIsFormUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormUpdated]);


  useEffect(() => {
    if (projectState.legislations?.length) {
      setCurrentEditCard(projectState.legislations[0]);
    }
  }, [projectState.legislations]);


  // Go back to the project details page if the user is not an admin
  if (!user.is_admin) {
    navigate(`/projects/${projectId}/details`);
  }


  return (
    <Fragment>
      <TopContent isDetails data-details-page>
        <BackLink>Back</BackLink>
        <div className={styles.root__topcontent}>
          <h3>Edit project</h3>
          <span data-is-published={projectState.project?.is_published} data-is-draft={!projectState.project?.is_published}>
            <small className={styles.root__pulser}>&nbsp;</small>
            {projectState.project?.is_published ? 'Published' : 'Draft'}
          </span>
        </div>
        <p>Set up the client project with client specific content. Details can be altered after the project has been created.</p>

        {/* Tabs */}
        <Tabs
          tabSelected={selectedIndex}
          options={PROJECT_DETAILS_TABLIST.map((tab) => tab.label)}
          onTabClick={tabClickHandler}
          data-tabs
          align="left"
          type="tabs"
          ref={tabRef}
        >
          <ButtonSet data-btn-set>
            <Button
              variation='cancel'
              size="small"
              type='button'
              url={`/projects/${projectId}/details`}
              title={`Cancel changes for: ${(projectState.project)?.name || ''}`}
            >
              Cancel
            </Button>
            <Button
              variation='secondary'
              size="small"
              type='button'
              disabled={isPending}
              title={`Save changes for: ${(projectState.project)?.name || ''}`}
              onClick={onSaveProjectAsDraftClickHandler}
            >
              {isPending ? 'Saving...' : 'Save changes'}
            </Button>
            <Button
                variation='primary'
                size="small"
                type='submit'
                aria-controls={`${PROJECT_EDIT_FORM_ID}-${projectId}`}
                form={`${PROJECT_EDIT_FORM_ID}-${projectId}`}
                title={`Publish: ${(projectState.project)?.name || ''}`}
                disabled={!canPublishProject}
              >
                Publish to client
              </Button>
          </ButtonSet>
        </Tabs>
      </TopContent>

      <article className={styles.root__article} data-content data-index={selectedIndex}>
        <div data-intro-container>
          <p>{PROJECT_EDIT_TAB_TITLE[selectedIndex]}</p>
          {selectedIndex === 3 ?(
            <ButtonSet data-btn-set>
              <Button size="small" disabled={true}>
                <IconComponent name="PlusFillIcon" />
                Add new legislation
              </Button>
            </ButtonSet>
          ) : null}
        </div>
        <Form
          id={`${PROJECT_EDIT_FORM_ID}-${projectId}`}
          data={{}}
          onSubmit={formSubmitHandler}
          onChange={onFormInputChange}
          ref={formRef}
        >
          {projectState.project ? (
            <Fragment>
              <ClientEditDetailsTab
                selectedIndex={selectedIndex}
                project={projectState.project}
              />
              <AdminSetupEditTab
                selectedIndex={selectedIndex}
                project={projectState.project}
                setIsFormUpdated={setIsFormUpdated}
              />
              <ContentConfigurationEditTab
                user={user}
                isEditing={isEditing}
                isPending={isPending}
                showToast={showToast}
                setIsEditing={setIsEditing}
                selectedIndex={selectedIndex}
                updateFilters={updateFilters}
                project={projectState.project}
                filters={projectState.filters}
                canRequestApproval={canSendForApproval}
                setCurrentEditCard={setCurrentEditCard}
                clientLegislations={projectState.clientLegislations}
              />
            </Fragment>
          ) : (
            <Loader data-medium />
          )}
        </Form>
      </article>

      {/* Edit modal */}
      <Modal
        id={LEGISLATION_DETAILS_ROLE_CONTENT_MODAL_ID}
        isOpen={isEditing}
        onOpen={() => setIsEditing(true)}
        onClose={() => setIsEditing(false)}
        data-variation="drawer"
      >
        {currentEditCard?.identifier && isEditing ? (
          <EditLegislation
            user={user}
            filters={projectState.filters}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            legislation={currentEditCard as any}
            editingType="project"
            data-drawer
          />
        ) : null }
      </Modal>
    </Fragment>
  );

};

export default withProjectHocs(withLegislationHocs(ProjectEdit));
