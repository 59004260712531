import { useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { normalizeImageUrl } from 'helpers/utils';

import { IconComponent } from 'components/Icon/Icon';
import { ProjectCardProps } from './ProjectCard.interface';

import styles from './ProjectCard.module.scss';


const ProjectCard = ({ project }: ProjectCardProps) => {
  /**
 * Compose avatar image.
 */
  const clientLogo = useMemo(
    () => normalizeImageUrl(project.logo).startsWith('data:image/jpeg;base64,') ? normalizeImageUrl(project.logo) : `data:image/jpeg;base64,${normalizeImageUrl(project.logo)}`,
    [project.logo],
  );

  return (
    <li className={styles.root} data-project={project.identifier}>
      <NavLink
        to={`/projects/${project.identifier}/details`}
        className={styles.root__card}
        title={`View details for: ${project.name}`}
      >
        <div>
          {project.logo ? (
            <img src={`${clientLogo}`} alt="Client Logo" />
          ) : (
            <IconComponent name="DefaultProjectIcon" />
          )}
          <span className={styles.root__tag}>
            <IconComponent name="DocumentMultipleFillIcon" />
            <small>{project?.legislation_count || 0}</small>
          </span>
        </div>
        <div>
          <h4>{project.name}</h4>
          <span>{project.description || 'No description available for this project'}</span>
          <span data-link>Go to Client Navigator View</span>
        </div>
      </NavLink>
    </li>
  );

};

export default ProjectCard;
