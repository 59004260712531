import { Fragment, useState, useEffect, forwardRef, useRef, useMemo } from 'react';

import {
  Aside,
  Modal,
  Loader,
  Button,
  Filters,
  TabList,
  TopContent,
  DragDropFile,
  IconComponent,
  EmptyLegislationList,
  LegislationListCard,
} from 'components';
import { ALLOW_TEMPLATE_DOWNLOAD } from 'configs/constants';
import { LEGISLATION_DETAILS_EDIT_MODAL_ID, MAX_FILTER_NUMBER } from 'configs/legislation/legislation';
import { useQueryApi } from 'hooks';
import { useAppStateContext } from 'contexts';
import { UPLOAD_LEGISLATION_FILE } from 'configs/api-endpoints';
import FilterItem from 'pages/Navigator/FilterItem';
import EditLegislation from './EditContent/EditLegislation';
import { ComponentProps, AdminUnionProps } from '../interfaces';
import { DragDropFileHandle } from './EditContent/TabPages/interfaces';
import { Legislation } from 'hooks/interfaces';
import styles from '../Legislation.module.scss';


// Type guard function to check if props are of type AdminProps
const isAdminProps = (props: ComponentProps): props is AdminUnionProps => {
  return props.type === 'ADMIN';
};


const AdminPage = forwardRef<HTMLDivElement, ComponentProps>((props, ref) => {

  const CAN_DOWNLOAD_TEMPLATE = ALLOW_TEMPLATE_DOWNLOAD === 'true';
  const { showToast } = useAppStateContext();

  const {
    tabs,
    query,
    user,
    filters,
    onRemove,
    isLoading,
    onSideFilterChange,
    onClearFilters,
    onFilterOption,
    onSort,
    onSearchChange,
  } = props;

  const dragDropRef = useRef<DragDropFileHandle | null>(null);

  const [currentEditCard, setCurrentEditCard] = useState<Legislation>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [showNewLegislation, setShowNewLegislation] = useState(false);
  const [legData, setLegData] = useState<{legislation_list: Legislation[], [key: string]: any }>({ legislation_list: [] });

  const { post: uploadLegislation } = useQueryApi(UPLOAD_LEGISLATION_FILE);
  const { data, mutate: uploadFile, isSuccess, isError, error, reset, isPending } = uploadLegislation();

  /**
   * Get only the filters that are actually selected
   */
  const activeFilters = useMemo(
    () => filters
      ?.filter((_f) => _f.data.some((d) => d.is_approved ? true : false))
      ?.map((f) => ({
        ...f,
        data: f.data.filter((_d) => _d.is_approved)
      }))?.map(({ data, ...rest }) => data).flat(),
    [filters]
  );

  const showFilters = useMemo(() => activeFilters.length > 0, [activeFilters]);


  /**
   * Auto select the tab which has entries.
   */
  useEffect(() => {
    const index = tabs.findIndex((entry) => entry.count > 0);
    setSelectedTab(index);
  }, [tabs]);


  /**
   * Show the uploaded legislations
   */
  useEffect(() => {
    if (isSuccess && data?.results?.length) {
      setShowNewLegislation(true);
      setLegData(data.results[0]);
    }
  }, [data, data?.results, isError, isSuccess]);


  /**
   * Hide the new legislations added after 5 seconds
   */
  useEffect(() => {
    if (showNewLegislation) {
      setTimeout(() => {
        setShowNewLegislation(false);
      }, 10000);
    }
  }, [showNewLegislation]);


  if (isAdminProps(props)) {
    const {
      onEdit,
      isEditing,
      legislation,
      setIsEditing,
    } = props;

    return (
      <Fragment>
        {/* Top Content */}
        <TopContent isDetails>
          <Fragment>
            <h3>Legislation Navigator List</h3>
            <div data-add-btn>
              <p>
                Explore the uploaded legislations list, offering an overview of the latest sustainability legislations and compliance requirements.
              </p>
              <Button variation='primary' onClick={() => setIsUploading(true)}>
                <IconComponent name="PlusFillIcon" />
                <span>Add new Legislation</span>
              </Button>
            </div>
          </Fragment>
        </TopContent>

        {/* Aside */}
        <Aside user={user}>
          <header data-aside-header data-legislation-page>
            <h4 className={styles.root__header__title}>Filter legislation list</h4>
            <span>Select filters to refine your results</span>
          </header>

          <Filters
            onSideFilterChange={onSideFilterChange}
            filters={filters}
            userRoles={user.profile.job_role_list}
            data-leg-list
            data-accordion
          />
        </Aside>

        {/* Main Content */}
        <section
          ref={ref}
          className={styles.root}
          data-is-editing={false}
          data-main-content
          data-is-first
        >
          <header className={styles.root__header}>
            <div className={styles.root__filtertop}>
              <h4>List of Legislations</h4>
              {activeFilters.length ? <Button
                variation="transparent"
                size="small"
                onClick={onClearFilters}
              >
                Clear all filters
              </Button> : null}
            </div>
            <ul className={styles.root__filters} data-hidden={!showFilters}>
              {activeFilters?.slice(0, MAX_FILTER_NUMBER).map((o, index: number) => (
                <FilterItem
                  key={`filter-${o.name}-${o.identifier}-${index}`}
                  hidden={false}
                  option={o}
                  onItemRemove={onRemove}
                />
              ))}
              {activeFilters.length > MAX_FILTER_NUMBER ? (
                <li data-total-filters>
                    <span>+&nbsp;{activeFilters.length - MAX_FILTER_NUMBER}&nbsp;</span>
                </li>
              ) : null}
            </ul>
            <TabList
              user={user}
              query={query}
              entries={tabs}
              onDataSort={onSort}
              isEditing={isEditing}
              selectedTab={selectedTab}
              onSearch={onSearchChange}
              setSelectedTab={setSelectedTab}
              onFilterOption={onFilterOption}
              variant='admin-tabs'
              showSearch
            >
              {tabs.map((tab, index) => (
                <Fragment key={index}>
                  {isLoading ?  <Loader data-details /> : (
                    <ul data-tab-list>
                      {tab.entries.length ? tab.entries.map((legislation: any, _index: number) => (
                        <LegislationListCard
                          key={legislation.identifier}
                          legislation={legislation}
                          user={user}
                          query={query}
                          isEditing={isEditing}
                          isActive={currentEditCard === legislation.identifier && isEditing}
                          onEdit={onEdit}
                          onEditButtonClick={() => setCurrentEditCard(legislation)}
                          data-tab-label={tab.label.toLowerCase().replace(' ', '_')}
                        />
                      )) : (
                        <EmptyLegislationList showContent={tab.entries.length === 0} query={query} />
                      )}
                    </ul>
                  )}
                </Fragment>
              ))}
            </TabList>
          </header>

          {/* {showNewLegislation && legData ? (
            <article className={styles.root__newly_uploaded}>
              <h6>{legData.legislation_list?.length || 0} New legislations added</h6>
              <p>
                {legData.legislation_list?.length || 0} new legislations from <strong>{legData?.file || 'File name unknown'}</strong> CSV file, were added to the legislation list.
              </p>
            </article>
          ) : null} */}
        </section>

        {/* Edit modal */}
        <Modal
          id={LEGISLATION_DETAILS_EDIT_MODAL_ID}
          isOpen={isEditing}
          onOpen={() => setIsEditing(true)}
          onClose={() => setIsEditing(false)}
          data-variation="drawer"
        >
          {currentEditCard?.identifier ? (
            <EditLegislation
              user={user}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              editingType="default"
              legislation={isEditing ? currentEditCard as Legislation : {} as Legislation}
              data-drawer
            />
          ) : null }
        </Modal>

        {/* Uploading modal */}
        {isUploading ? (
          <Modal
            id='legislation-upload-modal'
            isOpen={isUploading}
            onOpen={() => setIsUploading(true)}
            onClose={() => setIsUploading(false)}
            data-upload-content
          >
            <header data-upload-header>
              <h2>Add legislations</h2>
              <Button
                variation='transparent'
                onClick={() => setIsUploading(false)}
                size='small'
              >
                <IconComponent name="CloseOutlineIcon" />
              </Button>
            </header>
            <section data-upload-content>
              <div>
                <p>
                  <strong>Upload CSV Template:&nbsp;</strong>
                  Ensure your CSV file follows the template format. If the template is modified in any way that does not match the standard template, the upload will not succeed.
                </p>
                {CAN_DOWNLOAD_TEMPLATE ? <span>Don't have the CSV Template? Download it&nbsp;<a href="#!">here</a></span> : null}
              </div>
              <div data-drag-space>
                <DragDropFile
                  ref={dragDropRef}
                  onCancel={() => setIsUploading(false)}
                  reset={reset}
                  uploadFile={uploadFile}
                  isSuccess={isSuccess}
                  isError={isError}
                  error={error}
                  isPending={isPending}
                  data={data}
                  showToast={showToast}
                />
              </div>
            </section>
          </Modal>
        ) : null }
      </Fragment>
    );
  }

});

export default AdminPage;
